import * as actionTypes from "./actionTypes";

export const onCreateDeposit = (param, routeToCall, callback = () => {}) => {
  return {
    param,
    routeToCall,
    type: actionTypes.MANAGE_DEPOSIT,
    callback,
  };
};


export const onLoadAllDeposits = (routeToCall, callback = () => {}) => {
  return {
    routeToCall,
    type: actionTypes.ALL_DEPOSIT,
    callback,
  };
};

