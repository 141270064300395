import React, {Component} from "react";
import Section from "./layouts/Section";
import {WebLink, SiteWide} from "./customComponents";
import { userSelect, allRemoteSettingsSelect } from '../redux/selectors/index';
import { useSelector, useDispatch } from 'react-redux';
import {Images} from "./customComponents";
import parse from 'html-react-parser';
import {systemColor} from '../utility/styles';

    

function Term(){

    const site_settings = useSelector(allRemoteSettingsSelect);
    const systemColorManager = systemColor()


    return (
        <SiteWide title={"Terms and condition"} overlayBg>

            <div>
                <div className="container">

                    <div style={{...systemColorManager.txt_1}}>
                        {parse(site_settings?.site_settings?.term ?? '')}
                    </div>
                    
                </div>
            </div>

        </SiteWide>
    );

}

export default Term;