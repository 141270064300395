import * as actionTypes from "./actionTypes";

export const onPostExchange = (param, routeToCall, callback = () => {}) => {
  return {
    param,
    routeToCall,
    type: actionTypes.EXCHANGE_POST,
    callback,
  };
};


export const onGetExchange = (routeToCall, callback = () => {}) => {
  return {
    routeToCall,
    type: actionTypes.EXCHANGE_GET,
    callback,
  };
};

