import React, {useState, useEffect} from "react";
import Section from "../layouts/Section";
import {WebLink, SiteWide} from "../customComponents";
import { useSelector, useDispatch } from 'react-redux';

import {authActions} from '../../redux/actions';
import { userSelect, allRemoteSettingsSelect, homeScreenStatSelect} from '../../redux/selectors/index';
import Helper1, {notify, money, customNormalModalStyles} from '../../utility/Helper1';
import {homepage} from '../../utility/APIRequests';
import PinAuth from "../customComponents/PinAuth";


import Countdown from 'react-countdown';

import {Images} from "../customComponents";
import {systemColor} from '../../utility/styles';


function ChangePassword({history}){

    const userData = useSelector(userSelect);
    const site_settings = useSelector(allRemoteSettingsSelect);
    const finance = useSelector(homeScreenStatSelect);
    const dispatch = useDispatch();

    const [password, setPassword] = useState('')


    const [securityObj, setSecurityObj] = useState({})


    const [loading, setloading]   = useState();
    const [loadingText, setLoadingText]   = useState('Loading...');

    const systemColorManager = systemColor()


    const doPasswordChange = async (e) => {

        e.preventDefault();

        if(password == ''){
            notify('Please input new password', 0);
            return false;
        }

        const data = {password, ...(securityObj ?? {})};

        setloading(true);
        
        dispatch(authActions.onLogin("account/change_password", data, response => {
            
            if(response.code == 1){
                setSecurityObj({});
            }

            notify(response.msg, response.code)
            setloading(false);
        }),
        );

        
    }




    return (
        <SiteWide id="main" title={`Manage password`}>
                

            <form onSubmit={doPasswordChange} className="input-container" style={{...systemColorManager.no_bg}}>

                <div className="single-input-wrap">

                    <span className="new-input-label" style={{...systemColorManager.txt_1}}>New password</span>
                    <input placeholder={"New password"} onChange = {e => setPassword(e.target.value) } required="" className="form-field form-control" style={{...systemColorManager.bg_3}} type="text" />
                
                </div>

                <PinAuth resultingObjMethod={setSecurityObj} userData={userData} />


                <div>
                    <button type="submit" disabled={loading} className="btn mt-40 btn-block btn-danger" style={{...systemColorManager.btn_3}}>
                    Submit </button>
                </div>
                
            </form>



        </SiteWide>
    );

}

export default ChangePassword;