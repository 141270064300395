import React from 'react';

const headerColor  = "#000080";
const footerColor = "#191970";
const sideMenu2 = "#87ceeb";



export default {
  headerColor,
  footerColor,
  sideMenu2,
}