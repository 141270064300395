import React, { Component } from 'react';
import Routes from "./Routes";
import "./App.css";

class App extends Component {
    render() {
        return (
            <Routes />
        );
    }
}

export default App;
