import React, {Component} from "react";
import Section from "../layouts/Section";
import {WebLink, SiteWide} from "../customComponents";
import { userSelect, allRemoteSettingsSelect } from '../../redux/selectors/index';
import { useSelector, useDispatch } from 'react-redux';
import {systemColor} from '../../utility/styles';

    

function JivoChatWidget(props){

  React.useEffect(() => {
      const script = document.createElement("script");
      script.src = `//code.jivosite.com/widget/${props.widgetId}`;
      script.async = true;
      document.body.appendChild(script);
  }, [])

  return <div id="jivochat-widget" />;

}

export default JivoChatWidget;