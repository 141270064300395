export const userSelect 				= (state) => state.auth.user;
export const homeScreenStatSelect 		= (state) => state.finance.homescreenstat;
export const allEscrowSelect 			= (state) => state.escrow.allescrow;
export const allDepositSelect 			= (state) => state.deposit.depositdata;
export const allTradeSelect 			= (state) => state.trade.trades;
export const allWithdrawalSelect 		= (state) => state.withdrawal.withdrawaldata;
export const allRemoteSettingsSelect 	= (state) => state.settings.settings;
export const allFXSelect 				= (state) => state.fx.fxhead;
export const notificationSelect 		= (state) => state.notify.data;
export const exchangeSelect 			= (state) => state.exchanger.data;
export const footballSelect 			= (state) => state.football.footballdata;

