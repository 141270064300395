import React, {useState, useEffect} from "react";
import Section from "./layouts/Section";
import {WebLink, SiteWide} from "./customComponents";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import Helper1, {notify, niceDate, money} from '../utility/Helper1';
import {mainStyle, mainColor, header, otherColors} from '../utility/styles';
import CustomAgo from "./customComponents/CustomAgo";

import { useSelector, useDispatch } from 'react-redux';
import {authActions, withdrawalActions} from '../redux/actions';
import { userSelect, allRemoteSettingsSelect, homeScreenStatSelect } from '../redux/selectors/index';
import LoadingOverlay from 'react-loading-overlay';
import {Images} from "./customComponents";

import {RiUser4Line, RiMore2Fill} from "react-icons/ri";

import {
    Menu,
    MenuItem,
    MenuButton
} from '@szhsin/react-menu';
import '@szhsin/react-menu/dist/index.css';
import '@szhsin/react-menu/dist/transitions/slide.css';




function Salary() {    
    const [loadingText, setLoadingText]   = useState('Updating...');
    const [loading, setLoading]         = useState(false)
    
    const [mySalaryData, setMySalaryData]    = useState({})

    const site_settings = useSelector(allRemoteSettingsSelect);
    const finance = useSelector(homeScreenStatSelect);

    const dispatch = useDispatch();

    const loadSalary = (myrefid, level) => {
        
        setLoading(true)

        dispatch(authActions.onRefreshSystemData(`salary`, 10, {}, response => {
            setLoading(false)
            if(response.code == 1){
                setMySalaryData(response?.data?.mySalary)
            }
        }))
    }
    
    useEffect(() => {
        loadSalary(userData?.myrefid, 1);
    }, [])



    const requestSalary = () => {
        

        setLoading(true);
        setLoadingText('Requesting salary...');

        dispatch(authActions.onRefreshSystemData(`salary/request`, 10, {}, response => {
            notify(response.msg, response.code)
            if(response.code == 1){
                
            }
            setLoading(false);
        }))
    }

    const processSalaryWithdrawal = () => {
        

        setLoading(true);
        setLoadingText('Requesting salary...');

        dispatch(authActions.onLogin(`withdraw/salary`, {}, response => {
            notify(response.msg, response.code)
            if(response.code == 1){
                
            }
            setLoading(false);
        }))
    }

    const userData = useSelector(userSelect);
    const salaryData = site_settings.salary_settings;

    return (<SiteWide title="Earn salary">
        <Section overlayText={loadingText} loading={loading} title={'Earn salary'}>
            <div className="container">
               

                <div style={{marginTop: 120, padding: 10, backgroundColor: otherColors.cotpBlue, borderRadius: 10}}>
                    
                    <div style={{display: 'flex', justifyContent: 'space-between'}}>
                        <h3 className="text-info-1">{salaryData?.salaryHeadline}</h3>
                        <span style={{marginLeft: 10}}>
                            <Menu menuButton={<MenuButton style={{marginLeft: 10}}><RiMore2Fill size={20} color={otherColors.text}/></MenuButton>} transition>
                                <MenuItem onClick={requestSalary}>Request salary</MenuItem>
                            </Menu>
                        </span>
                    </div>

                    <hr />
                    <div className="text-info-1">
                        {salaryData?.salaryText}
                    </div>

                </div>

                    


                <div className="container" style={{marginTop: 30, backgroundColor: otherColors.cotpBlue, borderRadius: 10}}>
                    <div className="row">
                        
                        <div className="col-6 text-white">Remitted Salary</div>
                        <div className="col-6 text-white text-right">{money(finance?.filter((item) => item.type == salaryData?.salaryType)?.[0]?.salary ?? 0, salaryData?.salaryType ?? 0)}</div>

                        <div className="col-12"><hr /></div>

                        <div className="col-6 text-white">
                            Total active downline:
                        </div>
                        <div className="col-6 text-right text-white">{mySalaryData?.activedownlines}</div>
                    </div>

                    <br /><br />

                    <button onClick={() => processSalaryWithdrawal()} style={{backgroundColor: '#fff'}}  className={`btn-block b-white btn cursor-pointer`}>
                        Claim remitted salary
                    </button>


                </div>




            </div>
        </Section>
        </SiteWide>
    );

}

export default Salary;