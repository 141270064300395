import * as actionTypes from "./actionTypes";

export const onCreateWithdrawal = (param, routeToCall, callback = () => {}) => {
  return {
    param,
    routeToCall,
    type: actionTypes.MANAGE_WITHDRAWAL,
    callback,
  };
};


export const onLoadAllWithdrawals = (routeToCall, callback = () => {}) => {
  return {
    routeToCall,
    type: actionTypes.ALL_WITHDRAWALS,
    callback,
  };
};

