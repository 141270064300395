import React, {Component, useState, useEffect} from "react";

import Navbar from "../layouts/Navbar";
import {WebLink, SiteWide} from "../customComponents";
import NoData from "../customComponents/NoData";

import Section from "../layouts/Section";
import {useHistory, useLocation} from 'react-router-dom';

import {Images} from "../customComponents";
import Colors from '../../utility/Colors';
import Helper1, {notify, styles, money} from '../../utility/Helper1';

import {depositActions} from '../../redux/actions';
import { useSelector, useDispatch } from 'react-redux';
import { allDepositSelect, userSelect, allRemoteSettingsSelect, homeScreenStatSelect } from '../../redux/selectors/index';
import CustomAgo from "../customComponents/CustomAgo";
import TransactionHolder from "../customComponents/TransactionHolder";

import {systemColor} from '../../utility/styles';

    

function DepositHistory() {

    const userData = useSelector(userSelect);
    const site_settings = useSelector(allRemoteSettingsSelect);
    const [loadingText, setLoadingText]   = useState('Loading history...');

    const [loading, setloading]   = useState(false);
    const [deleting, setDeleting]   = useState(false);
    const [dataHasChanged, setDataHasChanged]   = useState(false);

    const [amount, setAmount]   = useState('');
    const [paymentPlaceLoading, setPaymentPlaceLoading]   = useState(false);

    const [enterAmountModal, setEnterAmountModal]   = useState(false);
    const [newDepositInfo, setNewDepositInfo]   = useState([]);
    const [modalIsOpen, setIsOpen] = React.useState(false);

    const finance = useSelector(homeScreenStatSelect);

    const dispatch = useDispatch();
    const loadHistoryData = useSelector(allDepositSelect);
    const systemColorManager = systemColor()


    const search = useLocation();
    const depositRequest = search?.state?.deposit;

    const loadAllDeposit = () => {

        setloading(true)
        setLoadingText('Loading payment history...')
        dispatch(depositActions.onLoadAllDeposits("deposit/load", response => {
            if(response.code == 1){
                setloading(false)
                setDataHasChanged(!dataHasChanged)
            }
        }))

    }



    useEffect(() => {
        loadAllDeposit()
    }, [])



    useEffect(() => {

        let pendingDeposit = loadHistoryData?.filter((item, index) => item.status < 2)

        if(pendingDeposit?.length > 0){
            setIsOpen(true);
            setNewDepositInfo(pendingDeposit)
        }

    }, [dataHasChanged])

    const { pathname } = useLocation();




    const status = ['Pending', 'Processing', 'Paid', 'Cancelled'];

    return (

        <SiteWide title={"Recharge Records"}>
           

                <TransactionHolder data={loadHistoryData}>

                
                    {loadHistoryData?.map((item, index) => {

                        let image = finance?.filter((filitem, index) => filitem.type == item.currency)

                        return <div className="ba-bill-pay-inner" style={{...systemColorManager.borders_color, ...systemColorManager.bg_1}}>
                            <div className="ba-single-bill-pay">
                                <div className="thumb" style={{...systemColorManager.no_bg, border: '0px solid #000'}}>
                                    <Images height={40} source={image?.[0]?.image} alt="img" />
                                </div>
                                <div className="details" style={{width: '100%'}}>
                                    <h5 style={{display: 'flex'}}>
                                        <span style={{...systemColorManager.txt_1}}>
                                            {money(item?.amountEnforcedToPay, item?.currency)}
                                        </span>
                                        <span style={{marginLeft: 'auto'}}>
                                            
                                        </span>
                                    </h5>
                                    <p style={{display: 'flex'}}>
                                        <span style={{...systemColorManager.txt_1}}>
                                            <CustomAgo time={item?.timestamp}/>
                                        </span>
                                        <div style={{marginLeft: 'auto', ...systemColorManager.txt_1}}>
                                            {item?.status >=2 && <span>{status[item?.status]}</span>}
                                            {item?.status <2 && <WebLink style={{...systemColorManager.txt_1, ...systemColorManager.btn_2, padding: 4, borderRadius: 4}} className="" to={"/deposit"}>Manage</WebLink>}
                                        </div>
                                    </p>
                                    {/*<p>{item.txn_id}</p>*/}
                                </div>

                                

                            </div>

                            {item?.status == 2 && <div className="amount-inner" style={{borderTop: '0.1px dashed #eee'}}>
                                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                    <h5 style={{...systemColorManager.txt_1}}>Amount Paid</h5>
                                    <h5 style={{...systemColorManager.txt_1}}>{money(item?.amountPaid, item?.currency)}</h5>
                                </div>
                            </div>}

                        </div>

                    })}


                </TransactionHolder>




                
            

        </SiteWide>
    );
}

export default DepositHistory;