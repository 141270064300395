import React, {useState, useEffect} from "react";
import Section from "./layouts/Section";
import {WebLink, SiteWide} from "./customComponents";
import { useSelector, useDispatch } from 'react-redux';

import {authActions} from '../redux/actions';
import { userSelect, allRemoteSettingsSelect, homeScreenStatSelect} from '../redux/selectors/index';
import Helper1, {notify, money, customNormalModalStyles} from '../utility/Helper1';
import {homepage} from '../utility/APIRequests';
import {CopyToClipboard} from 'react-copy-to-clipboard';


import Countdown from 'react-countdown';

import copy from 'copy-to-clipboard';
import {staticCountries} from '../utility/countries';
import {Images} from "./customComponents";
import Slider from "react-slick";
import { RiFileCopy2Fill, RiFileCopyLine, RiPhoneLockFill, RiBankFill,RiStackshareLine, RiWallet3Fill, RiArrowGoBackLine, RiEarthLine, RiLockUnlockLine, RiAccountBoxFill, RiSecurePaymentLine, RiListOrdered, RiLockPasswordFill, RiLoginBoxFill, RiMouseLine } from "react-icons/ri";
import {mainStyle, mainColor, header, footer, otherColors} from '../utility/styles';
// import reactImageSize from 'react-image-size';

function GoogleAuth({history}){

    const userData = useSelector(userSelect);
    const site_settings = useSelector(allRemoteSettingsSelect);

    const [imageSizes, setImageSizes] = useState([])
    let theUserRef = userData?.myrefid ?? '';
    let ref = site_settings?.site_settings?.site_url+'/#/'+(theUserRef!=''?'signup?ref='+theUserRef:'');

    
    const link = (image) => {
        
        return '<a href="' + ref + '" ><img src="' + image + '" /></a>';
    }

    // const onImgLoad = async (imageUrl) => {
    //     try {
    //         const { width, height } = await reactImageSize(imageUrl);
    //         return `${width}x${height}`
    //     } catch {

    //     }
    // }

    // const loadImage = (imageUrl) => {
    //   const img = new Image();
    //   img.src = imageUrl;

    //   img.onload = () => {
    //     let size = `${img.width}x${img.height}`
    //     // console.log(size)
    //     // let thisImageSize = [...imageSizes, {size}]
    //     // setImageSizes(thisImageSize)
    //     return 'Hello';
    //     // setImageDimensions({
    //     //   height: img.height,
    //     //   width: img.width
    //     // });
    //   };
    //   img.onerror = (err) => {
        
    //   };
    // };


    return (
        <SiteWide id="main" title={`Promotion Tool`}>
            <Section title={'Promotion Tool'}>
                
                <div className="input-container" style={{backgroundColor: 'transparent'}}>

                    {[2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map((item, index) => {
                        
                        let image = site_settings?.image_settings?.[0]?.['image' + item] ?? '';


                        if (image == ''){ return <></> } 

                        return <div style={{backgroundColor: '#fff', marginBottom: 80}}>
                            <Images className="w-100" src={image} alt="Promotional tool" />

                            <div style={{}}>
                                <div style={{padding: 10, wordBreak: 'break-all', wordWrap: 'break-word'}}>
                                    {link(image)}
                                    
                                    

                                </div>

                                <CopyToClipboard text={link(image)} style={{margin: 10, cursor: 'pointer'}} onCopy={() => notify('Copied', 1)}>
                                    <RiFileCopy2Fill size={20} />
                                </CopyToClipboard>
                            </div>

                        </div>  

                    })}
                </div>


            </Section>
        </SiteWide>
    );

}

export default GoogleAuth;