import React, {Component} from "react";
import Section from "./layouts/Section";
import {WebLink, SiteWide} from "./customComponents";
import { userSelect, allRemoteSettingsSelect } from '../redux/selectors/index';
import { useSelector, useDispatch } from 'react-redux';
import {Images} from "./customComponents";
import parse from 'html-react-parser';
import {systemColor} from '../utility/styles';

    

function About(){

    const site_settings = useSelector(allRemoteSettingsSelect);

    const systemColorManager = systemColor()

    return (
        <SiteWide id="main" title={"About"} overlayBg>

            <div>

                <div style={{...systemColorManager.txt_1}}>
                    {parse(site_settings?.content_settings?.[0]?.con1 ?? '')}
                </div>

            </div>

        </SiteWide>
    );

}

export default About;