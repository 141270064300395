import React, {useState, useEffect} from "react";
import Section from "./layouts/Section";
import CustomAgo from "./customComponents/CustomAgo";
import {WebLink, SiteWide, Images} from "./customComponents";
import {mainStyle, mainColor, header} from '../utility/styles';
import Modal from 'react-modal';
import { useSelector, useDispatch } from 'react-redux';
import {exchangeActions} from '../redux/actions';
import { userSelect, allRemoteSettingsSelect, homeScreenStatSelect, exchangeSelect } from '../redux/selectors/index';
import Helper1, {notify, styles, money, customModalStyles, moneyStatus, usefulColors} from '../utility/Helper1';
import { useHistory, useLocation } from "react-router-dom";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { ChatFeed, Message } from 'react-chat-ui'

function Chats() {

    const search = useLocation().search;
    const chatsource = new URLSearchParams(search).get("chatsource");

    const dispatch = useDispatch();
    const location = useLocation();

    const [loading, setloading]   = useState(false);
    const [loadingText, setLoadingText]   = useState('');
    const [chatId, setChatId]   = useState(chatsource);
    const [messageObject, setMessageObject]   = useState([]);
    const [chatMessage, setChatMessage]   = useState('');

    const allExchangers = useSelector(exchangeSelect);
    const userData = useSelector(userSelect);
    const finance = useSelector(homeScreenStatSelect);

    const loadChats = () => {
        dispatch(exchangeActions.onGetExchange("p2p/load/all", response => {
        }))
    }

    const submitChat = () => {
        if(isNaN(chatsource)){ notify('Cannot create message at the moment', 0); return; }
        dispatch(exchangeActions.onPostExchange({message: chatMessage}, `p2p/new/chat/${chatsource}`, response => {
            if(response.code == 1){
                setChatMessage('');
            }else{
                notify(response.msg, response.code)
            }

            
        }))
    }

    useEffect(() => {
        loadChats();
        const reloadEverytime = setInterval(() => {
            loadChats();
        }, 6000)

        return () => clearInterval(reloadEverytime);
        
    }, [])


    const manualFilter = () => {

        let messageArr = [];

        allExchangers.forEach((headItem, headIndex) => {
            let eachHeadItem = headItem?.peerData?.forEach((childItem, childIndex) => {
                if(childItem?.id == chatsource){
                    messageArr.push(childItem); 
                }
            })
        })

        let finalMessageArr = [];

        let eachMessageItem = messageArr[0]?.messageData?.slice().reverse().forEach((messageItem, messageIndex) => {
            
            messageItem.id          = (messageItem.senderAccID == userData.accountId)?0:1;
            messageItem.senderName  = messageItem.username
            finalMessageArr.push(new Message(messageItem))
        })

        return finalMessageArr;
    }

    
    return (
        <SiteWide id="main" title={"Chats"}>
            <Section hideHeader={true} overlayText={loadingText} loading={loading} title={'Chats'}>
                
               





                <div>
                    
                    <div class="card" >
                        <div class="card-body">
                            <div class="row">
                                <div class="col-xl-8">
                                    <div class="card">
                                        <div class="card-header bg-none fw-bold d-flex align-items-center">
                                            Discussion group
                                        </div>
                                        <div class="card-body bg-white bg-opacity-15" data-scrollbar="true" data-height="200px">
                                            <div class="widget-chat" style={{height: '70vh'}}>

                                                <ChatFeed
                                                  messages={manualFilter()} // Array: list of message objects
                                                  hasInputField={false} // Boolean: use our input, or use your own
                                                  showSenderName // show the name of the user who sent the message
                                                  bubblesCentered={false} //Boolean should the bubbles be centered in the feed?
                                                  
                                                />

                                            </div>
                                        </div>
                                        <div class="card-footer bg-none">
                                            <div class="input-group">
                                                <input type="text" value={chatMessage} onChange ={(e) => setChatMessage(e.target.value)} placeholder="Reply" class="form-control" />
                                                <button class="btn btn-primary" onClick ={submitChat} type="button"><i class="fa fa-paper-plane text-white"></i></button>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>




            </Section>
        </SiteWide>
    );

}

export default Chats;