import React, {Component, useState} from "react";
import {WebLink, Images} from "../customComponents";
// import { ToastContainer } from 'react-toastify';

import {useHistory} from "react-router-dom";
import MobileBreaker from "./MobileBreaker";
import {systemColor} from '../../utility/styles';

import { RiMenuLine } from "react-icons/ri";
import LoadingOverlay from 'react-loading-overlay';
import { useSelector, useDispatch } from 'react-redux';
import { userSelect, allRemoteSettingsSelect } from '../../redux/selectors/index';
import {authActions} from '../../redux/actions';
import {guessAdminRoute} from '../../utility/Helper1';

import Popup from 'reactjs-popup';
// import 'reactjs-popup/dist/index.css';


function Header(props) {
    
        const [uploadingAvatar, setUploadingAvatar]           = useState(false);
        const [showApp, setShowApp]           = useState(false);

        const hiddenFileInput = React.useRef(null);

        const systemColorManager = systemColor()
        const dispatch = useDispatch();

        const history = useHistory();
        const userData = useSelector(userSelect);
        const site_settings = useSelector(allRemoteSettingsSelect);

        const loadHome = () => {
            // if(history.goBack.length > 0){
            //     history.goBack() ?? history.push('/')
            // }else{
            //     history.push('/')
            // }

            history.goBack()
        }


        const progClickFileUpload = () => {
            if(!userData?.image){ history.push('/signin'); return;}
            hiddenFileInput.current.click();
        }


        const uploadAvatar = async (file) => {

            // instantUploadPreview(file, 'avatarPlace');
            setUploadingAvatar(true)

            dispatch(authActions.onLogin("account/change-avatar", {avatar:file}, response => {
                    setUploadingAvatar(false)
                }),
            )
      
        }

 
        const eachMenuItem = {maxHeight: '100%', display: 'flex', justifyContent: 'center', textAlign: 'center', alignItems: 'center'}


        const downloadLink = {
            android: guessAdminRoute() + '/assets/mobile-app/sril23league-android.apk',
            ios: null,
        }

        return <div className="" style={{position: 'fixed', width: '100%', zIndex: 999999999, top: 0, left: 0}}>
                    
                    <MobileBreaker>
                        
                        <div className="heder-area" style={systemColorManager.header}>
                            <div className="conainer">
                                <div className="" style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'stretch', height: 80}}>
                                    
                                    <div className="" style={{...eachMenuItem, justifyContent: 'flex-start', flex: 1}}>
                                        <div className="menu-bar" style={systemColorManager.txt_1, {width: '50px'}}>
                                            <RiMenuLine size={20} style={systemColorManager.txt_1}/>
                                        </div>
                                    </div>

                                    <WebLink to={'/'} className="logo text-center" style={{...eachMenuItem, flex: 1}}>
                                        <Images style={{ height: '50px' }} src={(site_settings?.site_settings?.logo)} alt="logo" />
                                    </WebLink>

                                    <div className="" style={{...eachMenuItem, flex: 1, display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'stretch'}}>

                                        <div className="text-center" style={{...eachMenuItem, background: showApp?'#fff':'transparent'}}>

                                            <div style={{}}>
                                                <Images onClick={() => setShowApp(we => !we)} className="cursor-pointer" height={30} style={{paddingRight: 5, paddingLeft: 3}} source={require('../../images/dl.webp')} alt={'download app'} />
                                            </div>

                                        </div>

                                        <div className="" style={{...eachMenuItem}}>

                                            <ul className="header-right" style={{paddingRight: 9, paddingLeft: 0, }}>
                                                <li style={{margin: 0}}>
                                                    <WebLink to={'/notification'}>
                                                        <i className="fa fa-bell animated infinite swing" />
                                                        <span style={{...systemColorManager.btn_2, width: 18, height: 18}} className="flexify badge">{userData?.totalNotifications ?? 0}</span>
                                                    </WebLink>
                                                </li>
                                            </ul>

                                        </div>

                                    </div>
                                </div>

                            </div>

                            {showApp && <div style={{}}>
                                
                                <div className=" soccerfont" style={{color: '#000', padding: 10, background: '#fff', color: '#8daa22'}}>
                                    <a href={downloadLink.android}>
                                        <Images height={30} width={30} style={{marginRight: 5}} source={require('../../images/android.png')} alt={'Android app'} />
                                            Download for Android
                                    </a>
                                </div>

                                <div className=" soccerfont" style={{color: '#000', padding: 10, background: '#8daa22', color: '#000'}}>
                                    <a href={downloadLink.ios ?? '#'}>
                                        <Images height={30} width={30} style={{marginRight: 5}} source={require('../../images/apple.png')} alt={'Android app'} />
                                        iOS version coming soon
                                    </a>
                                </div>

                            </div>}

                        </div>




                    </MobileBreaker>

                </div>


}

export default Header;