import React, {Component, useState, useEffect} from "react";

import Navbar from "../layouts/Navbar";
import {WebLink, SiteWide} from "../customComponents";
import NoData from "../customComponents/NoData";

import Section from "../layouts/Section";
import {useHistory, useLocation, useParams} from 'react-router-dom';

import {Images} from "../customComponents";
import Colors from '../../utility/Colors';
import Helper1, {byNextDate, notify, styles, money, formatTime, formatDate} from '../../utility/Helper1';

import {depositActions} from '../../redux/actions';
import { useSelector, useDispatch } from 'react-redux';
import { allDepositSelect, userSelect, allRemoteSettingsSelect, footballSelect, homeScreenStatSelect } from '../../redux/selectors/index';
import CustomAgo from "../customComponents/CustomAgo";

import {systemColor} from '../../utility/styles';
import { AiFillStar, AiFillSliders, AiOutlineFieldTime, AiOutlineComment, AiOutlineMail } from "react-icons/ai";
import EachFixture from "./EachFixture"
import PaginatedItems from "../customComponents/pagination/PaginatedItems";
import Slider from "react-slick";

import DownModal from "../layouts/DownModal";




const nextMatch = {
    slidesToShow: 2.5,
    slidesToScroll: 2,
    dots: false,
    infinite: false,
}



function AllFixtures() {

    const userData                          = useSelector(userSelect);
    const site_settings                     = useSelector(allRemoteSettingsSelect);
    const [loadingText, setLoadingText]     = useState('Loading history...');
    const footballData                      = useSelector(footballSelect);

    const [loading, setloading]             = useState(false);
    const [selectedOddObj, setSelectedOddObj]             = useState();
    
    const fullFootballData = byNextDate(footballData?.fixtures)
    const [homeFixture, setHomeFixture]   = useState(fullFootballData);
    const [leagueModal, setLeagueModal]   = useState(false);


    const history = useHistory();
    const systemColorManager = systemColor()



    const toggleLeaguePanel = () => {
        setLeagueModal(!leagueModal)
    }

    const eachIsClicked = (eachFixture) => {
        history.push(`fixture/${eachFixture?.fixture_id}`)
    }




    function Template({ currentItems }){

        return (
            <>
                {currentItems && currentItems.map((item) => <div style={{marginBottom: 20}}>
                    <EachFixture eachIsClicked={eachIsClicked} fromAllPage history={history} fixture={item} />
                </div>)}
            </>)
        
    }




    const newDataToShow = (newArray) => {
        toggleLeaguePanel()
        setHomeFixture(byNextDate([...newArray]))
    }

    const showAllLeague = (newArray) => {
        setLoadingText('Loading all leagues')
        setloading(true)
        setHomeFixture([...fullFootballData])

        setTimeout(() => {
            setLoadingText('')
            setloading(false)
        }, 2000)
    }




    const groupedData = fullFootballData?.reduce((acc, curr) => {
      const { league_name } = curr;
      if (!acc[league_name]) {
        acc[league_name] = [];
      }
      acc[league_name].push(curr);
      return acc;
    }, {});

    let leagues_name;

    if (groupedData && typeof groupedData === 'object') {
        leagues_name = Object.values(groupedData);
    } 

    return (

        <SiteWide loading={loading} overlayText={loadingText} title={'Games Market'}>
            
            <div className="" style={{marginBottom: 10, display: 'flex'}}>

                <div className="cursor-pointer" style={{display: 'flex', alignItems: 'center', ...systemColorManager.txt_1}} onClick = {toggleLeaguePanel}>
                    <AiFillSliders size={18} /> 
                    <span>Filter by league</span>
                </div>

                <div className="cursor-pointer" style={{marginLeft: 10, display: 'flex', alignItems: 'center', ...systemColorManager.txt_1}} onClick = {showAllLeague}>
                    <AiFillSliders size={18} /> 
                    <span>All league</span>
                </div>

            </div>
            

            {(homeFixture?.length ?? 0) > 0 && <PaginatedItems Template={Template} arrayOfDataToDisplay={homeFixture} itemsPerPage={10} />}



            <DownModal containerStyle={{...systemColorManager.bg_1}} isOpen={leagueModal} onClose={toggleLeaguePanel}>
                
                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
                    <div className="" style={{maxHeight: '70vh', width: '90%', overflowY: "auto" }}>

                        {leagues_name?.map((item) => <>
                                <div onClick={() => newDataToShow(item)} className="cursor-pointer text-center flexify" style={{marginBottom: 5, ...systemColorManager.btn_1, borderRadius: 10, padding: 10}}>
                                    <Images height={15} style={{marginRight: 5}} source={item?.[0]?.league_logo} alt={item?.[0]?.league_name} />
                                    {item?.[0].league_name} - {item?.[0].league_country} ({item?.length ?? 0})
                                </div>

                        </>)}
                       

                    </div>
                </div>

            </DownModal>

        </SiteWide>
    );
}

export default AllFixtures;