import { all,call, put, takeEvery } from "redux-saga/effects";
import * as actionTypes from "../actions/actionTypes";

import APIRequests from '../../utility/APIRequests';





function* onGetFixtures(action) {
  try {

    const response = yield (new APIRequests).runGet(action.routeToCall);


    if ((response.code == 1) && (response?.data?.fixtures)) {

      yield put({ type: actionTypes.SAVE_ALL_FIXTURE_DISK, data: response.data });

    }

    return action.callback?.(response);
  } catch (error) {

    return action.callback?.(error.response ?? error.message);
  }
}


function* onSubmitBet(action) {
  try {
    const response = yield (new APIRequests).runPost(action.routeToCall, action.param);
    if (response.code == 1) {

      if(response?.data?.finance){
        yield put({ type: actionTypes.HOME_SCREEN_STAT, homescreenstat: response.data.finance });
      }
      if(response?.data?.user_games){
        yield put({ type: actionTypes.SAVE_USER_BETS_DISK, data: response.data.user_games });
      }

    }

    return action.callback?.(response);
  } catch (error) {

    return action.callback?.(error.response ?? error.message);
  }
}


function* onLoadRecent(action) {
  try {
    const response = yield (new APIRequests).runGet(action.routeToCall);
    if (response.code == 1) {

      if(response?.data?.recent_bets){
        yield put({ type: actionTypes.SAVE_HOME_RECENT_DISK, data: response.data.recent_bets });
      }

    }

    return action.callback?.(response);
  } catch (error) {

    return action.callback?.(error.response ?? error.message);
  }
}



function* onLoadUserBets(action) {
  try {
    const response = yield (new APIRequests).runGet(action.routeToCall);
    if (response.code == 1) {

      if(response?.data?.user_games){
        yield put({ type: actionTypes.SAVE_USER_BETS_DISK, data: response.data.user_games });
      }

    }

    return action.callback?.(response);
  } catch (error) {

    return action.callback?.(error.response ?? error.message);
  }
}



function* watchOnLoadRecentBets() {
  yield takeEvery(actionTypes.LOAD_RECENT_BETS, onLoadRecent);
}

function* watchOnGetFixture() {
  yield takeEvery(actionTypes.GET_ALL_FIXTURE, onGetFixtures);
}

function* watchOnSubmitBet() {
  yield takeEvery(actionTypes.SUBMIT_BET_REQUEST, onSubmitBet);
}

function* watchOnLoadUserBets() {
  yield takeEvery(actionTypes.LOAD_USER_BETS, onLoadUserBets);
}



export default function* footballSagas() {
  yield all([watchOnGetFixture(), watchOnSubmitBet(), watchOnLoadRecentBets(), watchOnLoadUserBets()]);
}
