import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { userSelect } from '../redux/selectors/index';

    

// handle the public routes
function PublicRoute({ component: Component, ...rest }) {
  const users = useSelector(userSelect);

  return (
    <Route
      {...rest}
      render={(props) => (!users?.emailVerification) ? <Component {...props} /> : <Redirect to={{ pathname: '/' }} />}
    />
  )
}
 
export default PublicRoute;
