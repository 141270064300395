import React, {Component, useState} from "react";
import Section from "./layouts/Section";
import {WebLink, SiteWide} from "./customComponents";
import { userSelect, allRemoteSettingsSelect } from '../redux/selectors/index';
import {systemColor} from '../utility/styles';

import {Images} from "./customComponents";
import parse from 'html-react-parser';
import { useLocation } from 'react-router-dom';
import PaginatedItems from "./customComponents/pagination/PaginatedItems";
import { FaFacebook, FaYoutube, FaTwitter, FaInstagram, FaLinkedin, FaWhatsapp, FaTelegram } from 'react-icons/fa';
import { useSelector, useDispatch } from 'react-redux';

    

export default function SocialMediaIcons(){
    
    const site_settings = useSelector(allRemoteSettingsSelect);


    const theIcons = [
      { platform: 'facebook', color: '#3b5998',     url:    `${site_settings?.site_settings?.facebook}` },
      { platform: 'twitter', color: '#1da1f2',      url:    `${site_settings?.site_settings?.twitter}` },
      { platform: 'instagram', color: '#c13584',    url:    `${site_settings?.site_settings?.instagram}` },
      { platform: 'linkedin', color: '#0077b5',     url:    `${site_settings?.site_settings?.linkedin}` },
      { platform: 'whatsapp', color: '#25d366',     url:    `${site_settings?.site_settings?.whatsapp}` },
      { platform: 'telegram', color: '#0088cc',     url:    `${site_settings?.site_settings?.telegram}` },
      { platform: 'youtube', color: '#ff0000',     url:    `${site_settings?.site_settings?.youtube}` },


    ];

    const getIcon = (platform) => {
      switch (platform) {
        case 'facebook':
          return FaFacebook;
        case 'twitter':
          return FaTwitter;
        case 'instagram':
          return FaInstagram;
        case 'linkedin':
          return FaLinkedin;
        case 'whatsapp':
          return FaWhatsapp;
        case 'telegram':
          return FaTelegram;
        case 'youtube':
          return FaYoutube;
        default:
          return null;
      }
    };



    return (
        <div className="social-media-icons flexify">
          {theIcons.map((item) => {

            if(item.url === ''){ return <></> }

            const Icon = getIcon(item.platform);
            return (
              <a
                title={`${item.platform}`}
                key={item.platform}
                href={`${item.url}`}
                target="_blank"
                rel="noopener noreferrer"
                className="social-media-icon flexify"
                style={{ background: '#fff', borderRadius: 5, marginRight: 4, padding: 5 }}
              >
                <Icon size={24} color={item.color} />
              </a>
            );
          })}
        </div>
    );

};



