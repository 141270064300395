import React, {useState, useEffect} from "react";
import Section from "./layouts/Section";
import {WebLink, SiteWide} from "./customComponents";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import Helper1, {notify, niceDate, money} from '../utility/Helper1';
import {mainStyle, mainColor, header, otherColors} from '../utility/styles';
import CustomAgo from "./customComponents/CustomAgo";

import { useSelector, useDispatch } from 'react-redux';
import {authActions, withdrawalActions} from '../redux/actions';
import { userSelect, allRemoteSettingsSelect, homeScreenStatSelect } from '../redux/selectors/index';
import LoadingOverlay from 'react-loading-overlay';
import {Images} from "./customComponents";

import {RiUser4Line, RiMore2Fill} from "react-icons/ri";




function Agent() {    

    const userData = useSelector(userSelect);

    const [loadingText, setLoadingText]   = useState('Updating...');
    const [loading, setLoading]         = useState(false)
    
    const [phone, setPhone]             = useState(userData?.agent_phone ?? '')
    const [location, setLocation]       = useState(userData?.agent_location ?? '')
    const [fullname, setFullname]       = useState(userData?.agent_fullname ?? '')
    const [desc, setDesc]               = useState(userData?.agent_brief_desc ?? '')

    const site_settings = useSelector(allRemoteSettingsSelect);
    const finance = useSelector(homeScreenStatSelect);

    const dispatch = useDispatch();
    

    const applyForAgent = () => {
        

        setLoading(true);
        setLoadingText('Please wait...');

        dispatch(authActions.onLogin(`account/agent/update`, {phone, location, fullname, desc}, response => {
            notify(response.msg, response.code)
            if(response.code == 1){
                
            }
            setLoading(false);
        }))
    }


    return (<SiteWide title="Earn salary">
        <Section overlayText={loadingText} loading={loading} title={'Earn salary'}>
            





            <div className="container">
               

               <div style={{marginTop: 120, padding: 10, backgroundColor: otherColors.cotpBlue, borderRadius: 10}}>
                    
                    <div>
                        <h3 className="text-info-1">{site_settings?.operational_settings?.agentHeadline}</h3>
                    </div>

                    <hr />
                    <div className="text-info-1">
                        {site_settings?.operational_settings?.agentContent}
                    </div>

                </div>

            </div>


            <div className="container">
               

                <div style={{padding: 10, backgroundColor: otherColors.cotpBlue, borderRadius: 10}}>
                    
                    <div class="form-group">
                        <span className="text-info-1">Phone</span>
                        <input type="text" className="form-control form-field" onChange = {(e) => setPhone(e.target.value)} value={phone} />
                    </div>


                    <div class="form-group">
                        <span className="text-info-1">Location</span>
                        <textarea type="text" maxLength={120} className="form-control form-field" onChange = {(e) => setLocation(e.target.value)} value={location} />
                    </div>


                    <div class="form-group">
                        <span className="text-info-1">Full Name</span>
                        <input type="text" className="form-control form-field" onChange = {(e) => setFullname(e.target.value)} value={fullname} />
                    </div>



                    <div class="form-group">
                        <span className="text-info-1">Reason for application</span>
                        <textarea maxLength={300} type="text" className="form-control form-field" onChange = {(e) => setDesc(e.target.value)} value={desc}></textarea>
                    </div>

                    <div className="" style={{marginTop: 100}}>
                        <button type="submit" onClick={applyForAgent} disabled={loading} className="btn btn-block btn-primary" style={{backgroundColor: otherColors.btn1}}>
                        Submit </button>
                    </div>

                </div>

                    



            </div>
        </Section>
        </SiteWide>
    );

}

export default Agent;