import React, {Component, useState, useEffect} from "react";
import Footer from "../layouts/Footer";
import Navbar from "../layouts/Navbar";
import {WebLink, SiteWide} from "../customComponents";
import FullScreenInputHolder from "../customComponents/FullScreenInputHolder";
import SelectCurrency from "../customComponents/SelectCurrency";

import NoData from "../customComponents/NoData";

import Section from "../layouts/Section";
import {useHistory, useLocation} from 'react-router-dom';

import Slider from "react-slick";
import {Images} from "../customComponents";
import Colors from '../../utility/Colors';
import Helper1, {notify, styles, money, decimalCount, moneyStatus} from '../../utility/Helper1';

import {withdrawalActions} from '../../redux/actions';
import { useSelector, useDispatch } from 'react-redux';
import { allDepositSelect, userSelect, allRemoteSettingsSelect, homeScreenStatSelect } from '../../redux/selectors/index';

import LoadingOverlay from 'react-loading-overlay';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {systemColor} from '../../utility/styles';
import MobileBreaker from "../layouts/MobileBreaker";

import { AiOutlineWallet, AiFillCloseCircle, AiOutlineCopy, AiOutlineCloudUpload } from "react-icons/ai";
import CustomAgo from "../customComponents/CustomAgo";
import Countdown from 'react-countdown';
import PinAuth from "../customComponents/PinAuth";

const amountSuggestion = [10, 20, 50, 100, 200, 500, 800, 1000];


function DepositHistory() {

    const userData = useSelector(userSelect);
    const site_settings = useSelector(allRemoteSettingsSelect);
    const [loadingText, setLoadingText]   = useState('Loading history...');

    const [isOpen, setOpen] = useState(false);
    const [securityObj, setSecurityObj] = useState({})

    const [loading, setloading]   = useState(false);
    const [deleting, setDeleting]   = useState(false);

    const finance = useSelector(homeScreenStatSelect);
    const [currencySelected, setCurrencySelected]   = useState(finance?.[0]);
    const [currencyModal, setCurrencyModal]   = useState('');

    const allDepositData = useSelector(allDepositSelect);

    const [amount, setAmount]   = useState('');
    const [paymentPlaceLoading, setPaymentPlaceLoading]   = useState(false);


    const dispatch = useDispatch();
    const loadHistoryData = useSelector(allDepositSelect);


    const systemColorManager = systemColor()


    
    const processWithdrawal = (e) => {
        
        e.preventDefault();

        if((userData?.usdttrc20wallet?.length ?? 0) < '5'){ 
            notify('Please bind your wallet details before withdrawal.');
            return false;
        }

        if((isNaN(amount)) || (amount == 0)){
            // notify('Enter amount', 0);
            return false;
        }


        setloading(true);
        setLoadingText('Requesting withdrawal');

        dispatch(withdrawalActions.onCreateWithdrawal({amount: amount, ...(securityObj ?? {}), currency: currencySelected?.type, walletAddress: ''}, `withdrawal/new/0`, response => {
            
            notify(response.msg, response.code)
            if(response.code == 1){
                setAmount('');
            }

            setloading(false);

        }))
    }



    const updateCurrencySelected = (newCurObj) => {
        setCurrencySelected(newCurObj)
    }

    const toggleCurrencyModal = () => {
        setCurrencyModal(!currencyModal)
    }


    const newWithdrawal = () => {

        return <FullScreenInputHolder>

            <div>
                <button className="btn btn-info flexify" style={{color: '#000', borderRadius: 30}} onClick={toggleCurrencyModal}>
                    <AiOutlineWallet />
                    <div>{currencySelected.name} - {money(currencySelected.balance, currencySelected.type)}</div>
                </button>
            </div>

            <form onSubmit = {processWithdrawal} style={{}} action="">
                   
                <div class="single-input-wrap">
                   
                    <span className="new-input-label" style={{...systemColorManager.txt_1}}>Amount</span>

                    <div className="input-group">
                        <input type="text" placeholder="Amount" className="form-field form-control" onChange = {(e) => setAmount(e.target.value)} value={amount} style={{...systemColorManager.borders_color, ...systemColorManager.bg_3, ...systemColorManager.txt_1}} />
                        
                        {amount!='' && <div className="input-group-append" style={{position: 'absolute', right: 2, top: 5, zIndex: 5}}>
                            <span onClick={() => setAmount('')} className="input-group-text cursor-pointer input-appended-icon" id="basic-addon1" style={{...systemColorManager.txt_1}}>
                                <AiFillCloseCircle />
                            </span>
                        </div>}
                    </div>
                </div>


                <PinAuth resultingObjMethod={setSecurityObj} userData={userData} />




                <div class="single-input-wrap">

                    <button type="submit" disabled={loading} className="btn mt-40 btn-block btn-danger" style={{...systemColorManager.btn_3}}>
                    Submit </button>
                
                </div>

            </form>


            <div>
                <h4 style={{...systemColorManager.txt_1}}>
                    {currencySelected.withdrawal_note_headline}
                </h4>
                <p style={{...systemColorManager.txt_1}}>
                    {currencySelected.withdrawal_note_body}
                </p>
            </div>

        </FullScreenInputHolder>

    }












    return (

        <SiteWide loading={loading} overlayText={loadingText} title={"Withdrawal Action"}>
            
            
            {newWithdrawal()}

            <SelectCurrency selectedObj={currencySelected} curArrayToLoop={finance} isOpen={currencyModal} closeCurrencyModal={toggleCurrencyModal} onSelectCurrency={updateCurrencySelected} />


        </SiteWide>
    );
}

export default DepositHistory;


