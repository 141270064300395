import * as actionTypes from "../actions/actionTypes";

const initialState = {
  footballdata: null,
};

export default (state = initialState, action = {}) => {

  switch (action.type) {
    case actionTypes.SAVE_ALL_FIXTURE_DISK:


      return {
        ...state,
        footballdata: {
          ...state.footballdata,
          fixtures: action.data.fixtures
        },
      };
    
    case actionTypes.SAVE_USER_BETS_DISK:
      return {
        ...state,
        footballdata: {
          ...state.footballdata,
          user_games: action.data
        },
      };


    case actionTypes.SAVE_HOME_RECENT_DISK:
      return {
        ...state,
        footballdata: {
          ...state.footballdata,
          recent_bets: action.data
        },
      };


      
    default:
      return state;
  }
};
