import React, {Component, useState, useEffect} from "react";
import Footer from "../layouts/Footer";
import Navbar from "../layouts/Navbar";
import {WebLink, SiteWide} from "../customComponents";
import FullScreenInputHolder from "../customComponents/FullScreenInputHolder";
import SelectCurrency from "../customComponents/SelectCurrency";

import NoData from "../customComponents/NoData";

import Section from "../layouts/Section";
import {useHistory, useLocation} from 'react-router-dom';

import Slider from "react-slick";
import {Images} from "../customComponents";
import Colors from '../../utility/Colors';
import Helper1, {notify, styles, money, decimalCount, moneyStatus} from '../../utility/Helper1';

import {depositActions} from '../../redux/actions';
import { useSelector, useDispatch } from 'react-redux';
import { allDepositSelect, userSelect, allRemoteSettingsSelect, homeScreenStatSelect } from '../../redux/selectors/index';

import LoadingOverlay from 'react-loading-overlay';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {systemColor} from '../../utility/styles';
import MobileBreaker from "../layouts/MobileBreaker";

import { AiOutlineWallet, AiFillCloseCircle, AiOutlineCopy, AiOutlineCloudUpload } from "react-icons/ai";
import CustomAgo from "../customComponents/CustomAgo";
import Countdown from 'react-countdown';

const amountSuggestion = [10, 20, 50, 100, 200, 500, 800, 1000];


function DepositHistory() {

    const userData = useSelector(userSelect);
    const site_settings = useSelector(allRemoteSettingsSelect);
    const [loadingText, setLoadingText]   = useState('Loading history...');

    const [isOpen, setOpen] = useState(false);

    const [loading, setloading]   = useState(false);
    const [deleting, setDeleting]   = useState(false);

    const finance = useSelector(homeScreenStatSelect);
    const [currencySelected, setCurrencySelected]   = useState(finance?.[0]);
    const [currencyModal, setCurrencyModal]   = useState('');

    const allDepositData = useSelector(allDepositSelect);

    const [amount, setAmount]   = useState('');
    const [paymentPlaceLoading, setPaymentPlaceLoading]   = useState(false);


    const dispatch = useDispatch();
    const loadHistoryData = useSelector(allDepositSelect);


    const search = useLocation();
    const depositRequest = search?.state?.deposit;
    const systemColorManager = systemColor()


    const hiddenFileInput = React.useRef(null);
    const uploadPayment = React.useRef(null);

    const progClickPaymentUpload = () => {
        uploadPayment.current.click();
    }

    const updateCurrencySelected = (newCurObj) => {
        setCurrencySelected(newCurObj)
    }

    const toggleCurrencyModal = () => {
        setCurrencyModal(!currencyModal)
    }





    useEffect(() => {
        getPendingPayments()
    }, [])


    const getPendingPayments = () => {
        
        // setloading(true)
        // setLoadingText('Refreshing deposit records...')

        dispatch(depositActions.onLoadAllDeposits("deposit/load", response => {
            // notify(response.msg, response.code)
        }))

    }


     const uploadSelectedPayment = (file) => {
        
        setLoadingText('Uploading payment...')
        setloading(true)

        dispatch(depositActions.onCreateDeposit({txn_id:getPendingPayment?.[0]?.txn_id, image:file}, "deposit/manual-deposit-upload", response => {
            setloading(false)
            notify(response.msg,  response.code)
        }))

    }




    const getPendingPayment = allDepositData?.filter((item, index) => item.status < 2);

    const processDeposit = (e) => {
        
        e.preventDefault();

        if((isNaN(amount)) || (amount == 0)){
            notify('Enter amount', 0);
            return false;
        }

        setloading(true)

        setLoadingText('Processing request...')

        dispatch(depositActions.onCreateDeposit({amount, currency: currencySelected.type}, "deposit/new", response => {
            if(response.code == 1){
                setAmount('')
            }else{
                notify(response.msg, response.code)
            }

            setloading(false)
            
        }))
    }







    const deleteInv = (txn_id) => {

        if(getPendingPayment?.[0]?.status != 0){
            notify('Cannot be deleted as payment is being processed.', 0)
            return;
        }

        setloading(true)
        setLoadingText('Deleting request...')

        dispatch(depositActions.onLoadAllDeposits(`deposit/delete/${txn_id}`, response => {
            if(response.code == 1){
                // setWhichModal(1)
                // setOpenModal(true)
            }
            setloading(false)
        }))

    }


    const thePendingPayment = () => {

        let displayableAmount = getPendingPayment?.[0]?.useCryptoValue == 1?`${getPendingPayment?.[0]?.amountEnforcedToPay} ${getPendingPayment?.[0]?.abbr}`:`${getPendingPayment?.[0]?.amountEnforcedToPay} USD`
        let curDetails = finance?.filter((filitem, index) => filitem.type == getPendingPayment?.[0]?.currency)
        let perfectWalletobj;
        let eachParam;


        if((getPendingPayment?.[0]?.createdWithAutoOrManual == 0) && (getPendingPayment?.[0]?.autoConfirm == 2)){
            let pmAddress = JSON.parse(getPendingPayment?.[0]?.address);
            eachParam = Object.keys(pmAddress.param);
            perfectWalletobj = pmAddress
        }
        

        return <div className="ba-bill-pay-inner" style={{border: 'none', ...systemColorManager.no_bg}}>
            
            <div className="ba-single-bill-pay">
                <div className="thumb flexify">
                    <Images height={40} source={curDetails?.[0]?.image} alt="img" />
                </div>
                <div className="details" style={{width: '100%'}}>
                    <h5 style={{display: 'flex'}}>

                        {getPendingPayment?.[0]?.amountEnforcedToPay != '' && getPendingPayment?.[0]?.amountEnforcedToPay != 0 && <span className="flexify" style={{...systemColorManager.txt_1}}>
                            
                            <span style={{marginRight: 10}}>
                                {displayableAmount}
                            </span>

                            <CopyToClipboard text={getPendingPayment?.[0]?.amountEnforcedToPay} onCopy={() => notify('Copied', 1)}>
                                <AiOutlineCopy className="cursor-pointer" />
                            </CopyToClipboard>

                        </span>}

                        <span style={{marginLeft: 'auto', ...systemColorManager.txt_1}}>
                            
                        </span>
                        
                    </h5>
                    <p style={{display: 'flex'}}>
                        <span style={{...systemColorManager.txt_1}}>
                            {getPendingPayment?.[0]?.txn_id}

                            {" "}

                            <CopyToClipboard text={getPendingPayment?.[0]?.txn_id}
                            onCopy={() => notify('Transaction ID copied', 1)}>
                                <AiOutlineCopy className="cursor-pointer" />
                            </CopyToClipboard>

                            

                        </span>
                        <span className="text-info-1" style={{marginLeft: 'auto'}}>
                            
                        </span>
                    </p>
                    <p style={{display: 'flex', justifyContent: 'space-between'}}>
                        <span style={{...systemColorManager.txt_1}}>
                            Status: {moneyStatus()[getPendingPayment?.[0]?.status]}
                            {/*<CustomAgo time={getPendingPayment?.[0]?.timestamp}/>*/}
                        </span>
                        <span>

                            
                            {getPendingPayment?.[0]?.createdWithAutoOrManual == 0 && getPendingPayment?.[0]?.autoConfirm == 2 && <span>
                                <form action={perfectWalletobj?.form_url} method="POST" style={{display: 'inline'}}>
                                    {eachParam.map((eachkey, index) => (
                                            <input name={eachkey} value={perfectWalletobj.param[eachkey]} type="hidden" />
                                        ))}
                                    <button style={{textDecoration: 'underline', ...systemColorManager.txt_1}} type="submit">Pay Now</button>
                                </form>

                                </span>}

                                 {" "}{" "}{" "}
                            
                                <span className="text-dar" style={{marginTop: '-120px', position: 'absolute', top: 140, right: 12, cursor: 'pointer'}} onClick={() => window.confirm('Click OK to confirm delete') && deleteInv(getPendingPayment?.[0]?.txn_id)}>
                                    <AiFillCloseCircle color ='#f70' size={20} />
                                </span>
                        </span>
                    </p>
                </div>

            </div>



            <div className="amount-inner">
                
                <div className="flexify" style={{marginBottom: 10}}>
                    <h5 className="text-info-1"></h5>
                    {(getPendingPayment?.[0]?.autoConfirm == 1) && 1>2 && (getPendingPayment?.[0]?.createdWithAutoOrManual == 0) && <small className="text-info-1">{getPendingPayment?.[0]?.blockConfirmationsAchieved} Block Confirmation</small>}
                </div>

                {getPendingPayment?.[0]?.autoConfirm == 1 && <div className="text-center">
                        <div style={{display: 'flex', justifyContent: 'center'}}>
                            <div>
                                <h6>
                                    <CopyToClipboard text={getPendingPayment?.[0]?.address}
                                        onCopy={() => notify('Copied', 1)}>
                                        <span className="text-dark cursor-pointer">
                                            {getPendingPayment?.[0]?.address} <AiOutlineCopy />
                                        </span>
                                    </CopyToClipboard>
                                </h6>
                                <small style={{...systemColorManager.txt_1}}>Deposit exactly {displayableAmount} to this address</small><br/>
                                <small className="text-danger">{getPendingPayment?.[0]?.paymentMessage}</small><br/>


                                    {(getPendingPayment?.[0]?.creationCryptoMerchant == 3 || getPendingPayment?.[0]?.timeout !='') && <small>
                                        <Countdown
                                            date={new Date(getPendingPayment?.[0]?.timeout)}
                                            renderer={({ hours, minutes, seconds, completed }) => {
                                              if (completed) {
                                                return <span>Payment timeout</span>
                                              } else {
                                                return <span>{hours}:{minutes}:{seconds}</span>;
                                              }
                                            }}
                                        />
                                    <br/></small>}


                               
                        </div>

                    </div>

                    <div className="text-center" style={{}}>
                        <div style={{backgroundColor: '#f90', display: 'inline-block', marginTop: 20}}>
                            <Images style={{fill: "red"}} src={getPendingPayment?.[0]?.qrCode} />
                        </div>
                    </div>


                    <div style={{marginTop: 20}}>

                        {getPendingPayment?.[0]?.createdWithAutoOrManual == 1 && <span>
                            <span className="h5 flexify" onClick={() => progClickPaymentUpload()}  style={{cursor: 'pointer', ...systemColorManager.txt_1}}>
                                <AiOutlineCloudUpload size={18} /> <span style={{marginLeft: 4}}>{(getPendingPayment?.[0]?.image == null)?'Upload':'Re-upload'} Payment</span>
                            </span>
                            <input style={{display: 'none'}} ref={uploadPayment} onChange={(e) => uploadSelectedPayment(e.target.files[0])} type="file" accept=".jpg, .gif, .png, .jpeg"/>
                        </span>}

                    </div>

                </div>}


                {getPendingPayment?.[0]?.autoConfirm == 0 && <div style={{display: 'flex', justifyContent: 'space-between'}}>
                    <h6 className="text-info-1">
                        {curDetails?.[0]?.accountNameText}
                    </h6>

                    <h6>
                        <span className="text-info cursor-pointer">
                            {curDetails?.[0]?.accountName}
                        </span>
                    </h6>

                    
                </div>}

                {getPendingPayment?.[0]?.autoConfirm == 0 && <div style={{display: 'flex', justifyContent: 'space-between'}}>
                    <h6 className="text-info-1">
                        {curDetails?.[0]?.accountNumberText}
                    </h6>

                    <h6 className="text-info-1">
                        <CopyToClipboard text={curDetails?.[0]?.accountNumber}
                            onCopy={() => notify('Copied', 1)}>
                            <span className="text-info cursor-pointer">
                                {curDetails?.[0]?.accountNumber}
                                <AiOutlineCopy />
                            </span>
                        </CopyToClipboard> | <span className="text-info cursor-pointer">{curDetails?.[0]?.bankName}</span>
                    </h6>

                    
                </div>}

            </div>

        </div>


    }


// console.log('finance', finance)

    const newPayment = () => {

        return <FullScreenInputHolder>

            <div>
                <button className="btn btn-info flexify" style={{color: '#000', borderRadius: 30}} onClick={toggleCurrencyModal}>
                    <AiOutlineWallet />
                    <div>{currencySelected.name}</div>
                </button>
            </div>

            <form onSubmit = {processDeposit} style={{}} action="">
                   
                <div class="single-input-wrap">
                   
                    <span className="new-input-label" style={{...systemColorManager.txt_1}}>Amount</span>

                    <div className="input-group">
                        <input type="text" className="form-field form-control" onChange = {(e) => setAmount(e.target.value)} value={amount} style={{...systemColorManager.borders_color, ...systemColorManager.bg_3, ...systemColorManager.txt_1}} />
                        
                        {amount!='' && <div className="input-group-append" style={{position: 'absolute', right: 2, top: 5, zIndex: 5}}>
                            <span onClick={() => setAmount('')} className="input-group-text cursor-pointer input-appended-icon" id="basic-addon1" style={{...systemColorManager.txt_1}}>
                                <AiFillCloseCircle />
                            </span>
                        </div>}
                    </div>
                </div>


                <div className="row" style={{marginTop: 30}}>
                    {amountSuggestion.slice(0, 4).map((sugg) => <div class="col" style={{}}><button type="button" onClick = {() => setAmount(sugg)} className="btn btn-info btn-block" style={{...systemColorManager.txt_1, ...systemColorManager.borders_color, borderColor: '#f90', borderWidth: 1, ...systemColorManager.bg_1, padding: 0, }}>{sugg}</button></div>)}
                </div>

                <div className="row" style={{marginTop: 30}}>
                    {amountSuggestion.slice(4, 10).map((sugg) => <div class="col"><button type="button" onClick = {() => setAmount(sugg)} className="btn btn-info btn-block" style={{...systemColorManager.txt_1, border: '#f90 solid 1px', borderWidth: 1, ...systemColorManager.bg_1, padding: 0}}>{sugg}</button></div>)}
                </div>


                <div class="single-input-wrap">

                    <button type="submit" disabled={loading} className="btn mt-40 btn-block btn-danger" style={{...systemColorManager.btn_3}}>
                    Submit </button>
                
                </div>

            </form>


            <div>
                <h4 style={{...systemColorManager.txt_1}}>
                    {currencySelected.payment_note_headline}
                </h4>
                <p style={{...systemColorManager.txt_1}}>
                    {currencySelected.payment_note_body}
                </p>
            </div>

        </FullScreenInputHolder>

    }












    return (

        <SiteWide loading={loading} overlayText={loadingText} title={"Fund Recharge"}>
            
            
            {(getPendingPayment?.length ?? 0) == 0?newPayment():thePendingPayment()}



            

            <SelectCurrency selectedObj={currencySelected} curArrayToLoop={finance} isOpen={currencyModal} closeCurrencyModal={toggleCurrencyModal} onSelectCurrency={updateCurrencySelected} />


        </SiteWide>
    );
}

export default DepositHistory;


