import React, {Component, useState, useEffect} from "react";
import Section from "./layouts/Section";
import {WebLink, SiteWide, Images} from "./customComponents";
import {systemColor} from '../utility/styles';
import { useSelector, useDispatch } from 'react-redux';
import {footballActions} from '../redux/actions';
import Helper1, {byNextDate, niceDate, formatTime, formatDate, displayTime, notify, styles, money, FormatOnly, customNormalModalStyles, moneyStatus} from '../utility/Helper1';
import { FaRegClock } from "react-icons/fa";



function Notification() {

        const [pageLoading, setPageLoading] = useState(true);
        const [notiData, setNotiData] = useState([]);
        const [newPageNumber, setNewPageNumber] = useState(1);

        const dispatch = useDispatch();


        const getPageData = (pageNumber = 1) => {

            setPageLoading(true)

            dispatch(footballActions.onGetRecents(`user/notifications?page=${pageNumber}`, response => {

                setPageLoading(false)

                let newData;

                if(pageNumber > (notiData?.currentPage ?? 1)){
                    newData = {...response?.data, data: [...notiData?.data, ...response?.data?.data]}
                }else{
                    newData = response.data
                }

                setNotiData({...newData})

            }))
        }

        useEffect(() => {
            getPageData()
        }, [])


        const showNext = () => {
            let newPage = newPageNumber + 1
            setNewPageNumber(newPage)
            getPageData(newPage)
        }


        const systemColorManager = systemColor()


        const renderLoadMoreButton = () => {
            if (notiData?.currentPage < notiData?.totalPages) {
              return (
                <button className="btn btn-info" style={{...systemColorManager.btn_1}} onClick={showNext}>
                  Load More
                </button>
              );
            }
        };

        return (
            <SiteWide loading={pageLoading} overlayText={"Loading..."} title={'Notification'}>
                <div className="transaction-area pd-top-36 mg-bottom-40">
                        

                        {notiData?.data?.map((item, index) => {

                            return <div className="ba-bill-pay-inne" style={{padding: 10, marginBottom: 10, ...systemColorManager.borders_color, ...systemColorManager.bg_1}}>
                                <div className="ba-single-bill-pay">
                                    
                                    <div className="details" style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word'}}>

                                        <p style={{...systemColorManager.txt_1, fontSize: 18}}>
                                        {item.message}</p>
                                        
                                        <div style={{display: 'flex', alignItems: 'center', marginTop: 20, }}>
                                            <FaRegClock color={systemColorManager.txt_singly_2} size="13" /> 
                                            <span style={{marginLeft: 5, ...systemColorManager.txt_2}}>{formatDate(new Date(item.timestamp * 1000))} {formatTime(new Date(item.timestamp * 1000))}</span>
                                        </div>

                                    </div>

                                </div>
                                
                            </div>})}



                        

                    <div className="text-center" style={{marginTop: 10}}>
                        {renderLoadMoreButton()}
                    </div>

                </div>
            </SiteWide>
        );

}

export default Notification;