import React, {useState, useEffect} from "react";
import Section from "./layouts/Section";
import {WebLink, SiteWide} from "./customComponents";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import Helper1, {notify, niceDate} from '../utility/Helper1';
import APIRequests from '../utility/APIRequests';

import { useSelector, useDispatch } from 'react-redux';
import {authActions} from '../redux/actions';
import { userSelect, allRemoteSettingsSelect } from '../redux/selectors/index';
import LoadingOverlay from 'react-loading-overlay';
import {Images} from "./customComponents";


function Referrals() {    

    const [loading, setLoading]         = useState(false)
    const [testimony, setTestimony]    = useState('')
    const [title, setTitle]    = useState('')
    
    const site_settings = useSelector(allRemoteSettingsSelect);


    const dispatch = useDispatch();
    const userData = useSelector(userSelect);


    const submitTestimony = async () => {

        if(testimony == ''){
            notify('Please enter a feedback', 0);
            return;
        }

        if(title == ''){
            notify('Please enter a title', 0);
            return;
        }

        setLoading(true)

        dispatch(authActions.onRefreshSystemDataPOST("testimony/new", 10, {testimony, title}, response => {
            notify(response.msg, response.code)
            setLoading(false)
            setTestimony('')
            setTitle('')
        }))


        
    }


    return (<SiteWide title="Testimony">
        <Section overlayText={'Submitting testimony'} loading={loading} title={'Testimony'}>
            <div className="container-inner">
                <div className="container-fluid">
                    <div className="row flex-row">
                        <div className="col-xl-12">
                            {/* Basic Tabs */}


                                <div className="wadow">
                                    
                                    
                                    <input maxLength={120} placeholder="Title" style={{backgroundColor: 'rgba(0, 0, 0, 0.5)', border: 0, color: '#fff'}} onChange = {e => setTitle(e.target.value) }  value={title} required="" className="form-control" type="text" />
                                    
                                    <br />

                                    <textarea maxLength={600} style={{backgroundColor: 'rgba(0, 0, 0, 0.5)', border: 0, color: '#fff'}} placeholder="Feedback" required rows={10} className="form-control" value={testimony} onChange={(e) => setTestimony(e.target.value)} />
                                    
                                    <div>
                                        <br />
                                        <button onClick={submitTestimony} className="btn btn-info btn-sm btn-block auth-input">Submit</button>
                                    </div>

                                </div>


                        </div>
                        
                    </div>
                    {/* End Row */}
                </div>
            </div>
        </Section>
        </SiteWide>
    );

}

export default Referrals;