import React, {useState, useEffect} from "react";
import {Images} from "./index";
import {systemColor} from '../../utility/styles';
import { useSelector } from 'react-redux';
import { allRemoteSettingsSelect } from '../../redux/selectors/index';



function PinAuth(props) {

    const {setWithdrawalPin, setGoogleAuth, userData, resultingObjMethod} = props
    const systemColorManager = systemColor()
    const site_settings = useSelector(allRemoteSettingsSelect);

    const op_se = site_settings.operational_settings

    const [securityState, setSecurityState] = useState({});

    const updateSecurityState = (labelHolder, newValue) => {

        let oldSeState = securityState
        oldSeState[labelHolder] = newValue

        resultingObjMethod({...oldSeState})
        setSecurityState({...oldSeState})


    }


    return <div>
        
        {(op_se.use_pin == 1) && <div class="single-input-wrap">

            <span className="new-input-label" style={{...systemColorManager.txt_1}}>Account Pin</span>

            <input maxLength={4} autoComplete="new-password" placeholder="Pin" onChange = {e => updateSecurityState('withdrawalPin', e.target.value) } required="" className="auth-inpu  form-field form-control" type="password" style={{...systemColorManager.bg_3, ...systemColorManager.borders_color}}/>
        
        </div>}





        {(userData?.use_google_auth == 1 && op_se.use_google_auth == 1) && <div class="single-input-wrap">
            <span className="new-input-label" style={{...systemColorManager.txt_1}}>Google Auth</span>
            <input autoComplete="new-password" placeholder="Code" onChange = {e => updateSecurityState('googleAuth', e.target.value) } required="" className="auth-inpu  form-field form-control" type="password" style={{...systemColorManager.bg_3, ...systemColorManager.borders_color}}/>
        </div>}




        {(op_se.use_old_password == 1) && <div class="single-input-wrap">
            <span className="new-input-label" style={{...systemColorManager.txt_1}}>Current Login Password</span>
            <input autoComplete="new-password" placeholder="Current login password" onChange = {e => updateSecurityState('oldPassword', e.target.value) } required="" className="auth-inpu  form-field form-control" type="password" style={{...systemColorManager.bg_3, ...systemColorManager.borders_color}}/>
        </div>}

    </div>

}

export default PinAuth;