import { combineReducers } from "redux";
import authReducer from "./auth";
import fxReducer from "./fx";
import depositReducer from "./deposit";
import withdrawalReducer from "./withdrawal";
import financeReducer from "./finance";
import all_remote_settingsReducer from "./all_remote_settings";
import tradeReducers from "./trade";
import notificationReducers from "./notification";
import exchangeReducers from "./exchange";
import footballReducers from "./football";
import { store } from "../store";


import * as actionTypes from "../actions/actionTypes";
import storage from 'redux-persist/lib/storage'

const appReducer = combineReducers({
  
  auth: authReducer,
  finance: financeReducer,
  settings: all_remote_settingsReducer,
  fx: fxReducer,
  deposit: depositReducer,
  withdrawal: withdrawalReducer,
  trade: tradeReducers,
  notify: notificationReducers,
  exchanger: exchangeReducers,
  football: footballReducers,
  
});

export const rootReducer = (state, action) => {

  // before
  // if (action.type === actionTypes.LOGOUT) {
  //   storage.removeItem('persist:root')
  //   return appReducer(undefined, action)
  // }

  // now, so that settings and football data wount be wiped on logout
  if (action.type === actionTypes.LOGOUT) {
    // Get the persisted state from storage

    const settings = state?.settings;
    const football = state?.football;

    // Remove only the persisted state of the "auth" reducer
    storage.removeItem('persist:root')
    // Return the app state with the persisted state of "settings" and "football"
    return {
      ...appReducer(undefined, action),
      settings,
      football
    }
  }

  return appReducer(state, action)

}