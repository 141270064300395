import React, {Component, useState, useEffect} from "react";
import Footer from "../layouts/Footer";
import Navbar from "../layouts/Navbar";
import {WebLink, SiteWide} from "../customComponents";
import Section from "../layouts/Section";
import {useHistory, useLocation} from 'react-router-dom';

import Slider from "react-slick";
import {Images} from "../customComponents";
import Colors from '../../utility/Colors';
import Helper1, {notify, styles, money} from '../../utility/Helper1';
import NoData from "../customComponents/NoData";

import {withdrawalActions} from '../../redux/actions';
import { useSelector, useDispatch } from 'react-redux';
import { allWithdrawalSelect, allDepositSelect, userSelect, allRemoteSettingsSelect, homeScreenStatSelect } from '../../redux/selectors/index';
import Modal from 'react-modal';
import LoadingOverlay from 'react-loading-overlay';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en.json'
import CustomAgo from "../customComponents/CustomAgo";
import {systemColor} from '../../utility/styles';
import TransactionHolder from "../customComponents/TransactionHolder";


function DepositHistory() {


    const search = useLocation();

    const userData = useSelector(userSelect);
    const site_settings = useSelector(allRemoteSettingsSelect);

    const [loading, setloading]   = useState(false);
    const [amount, setAmount]   = useState();
    const [paymentPlaceLoading, setPaymentPlaceLoading]   = useState(false);
    const [withdrawalPin, setWithdrawalPin]   = useState('');
    const [walletAddress, setWalletAddress]   = useState('');


    const [enterAmountModal, setEnterAmountModal]   = useState(search?.state?.withdraw ?? false);
    const [newDepositInfo, setNewDepositInfo]   = useState();
    const [changeWhichWallet, setChangeWhichWallet]   = useState(100);


    TimeAgo.addDefaultLocale(en)
    const timeAgo = new TimeAgo('en-US')



    const [modalIsOpen, setIsOpen] = React.useState(false);

    const finance = useSelector(homeScreenStatSelect);

    const dispatch = useDispatch();
    const loadHistoryData = useSelector(allWithdrawalSelect);
    const systemColorManager = systemColor()



    const loadAllWithdrawal = () => {

        setloading(true)
        dispatch(withdrawalActions.onLoadAllWithdrawals("withdrawal/load", response => {

            if(response.code == 1){
                setloading(false)
            }
        }))

    }

    useEffect(() => {
        loadAllWithdrawal()
    }, [])


    



    const whichWallet = ['Balance', 'Commission']

    const status = ['Processing', 'Processing', 'Paid', 'Cancelled'];

    return (

        <SiteWide id="main" title={"Withdrawal Records"}>

            <TransactionHolder data={loadHistoryData}>

                {loadHistoryData?.map((item, index) => { 

                    let image = finance?.filter((filitem, index) => filitem.type == item.currency)

                    return <div className="ba-bill-pay-inner" style={{...systemColorManager.borders_color, ...systemColorManager.bg_1}}>
                        <div className="ba-single-bill-pay">
                            <div className="thumb" style={{...systemColorManager.no_bg, border: '0px solid #000'}}>
                                <Images height={40} source={image?.[0]?.image} alt="img" />
                            </div>
                            <div className="details" style={{width: '100%'}}>
                                <h5 style={{display: 'flex'}}>
                                    <span style={{...systemColorManager.txt_1}}>
                                        {money(item?.readOnlyTotalWithdrawn, item?.currency)}
                                    </span>
                                    <span style={{marginLeft: 'auto'}}>
                                        
                                    </span>
                                </h5>
                                <p style={{display: 'flex'}}>
                                    <span>
                                        <CustomAgo time={item?.timestamp}/>
                                    </span>
                                    <span style={{marginLeft: 'auto', ...systemColorManager.txt_1}}>
                                        {status[item?.status]}
                                    </span>
                                </p>
                                {/*<p>{item.txn_id}</p>*/}
                            </div>

                            

                        </div>
                        <div className="amount-inner" style={{borderTop: '0.1px dashed #eee'}}>
                            <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                <h5 style={{...systemColorManager.txt_1}}>Amount Paid</h5>
                                <h5 style={{...systemColorManager.txt_1}}>{money(item?.amount, item?.currency)}</h5>
                            </div>
                        </div>
                    </div>

                })}

            </TransactionHolder>

        </SiteWide>
    );
}

export default DepositHistory;

