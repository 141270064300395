
import { createStore, applyMiddleware, compose } from 'redux';
import {rootReducer} from '../redux/reducers/index.js';
import rootSaga from '../redux/sagas/index';

import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web


import createSagaMiddleware from 'redux-saga';


const persistConfig = {
  key: 'root',
  storage,
}
const persistedReducer = persistReducer(persistConfig, rootReducer)

const sagaMiddleware = createSagaMiddleware();

// let store = createStore(persistedReducer, applyMiddleware(sagaMiddleware));
const store = compose(
  applyMiddleware(sagaMiddleware),
  // window?.devToolsExtension && window?.devToolsExtension?.()
)(createStore)(persistedReducer)
// const store = createStore(rootReducer);


let persistor = persistStore(store)
sagaMiddleware.run(rootSaga);

export { store, persistor }
