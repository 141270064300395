import React from "react";
import {systemColor} from '../../utility/styles';
import MobileBreaker from "../layouts/MobileBreaker";
import DownModal from "../layouts/DownModal";


export default function SelectCurrency(props){
    

	const {isOpen, closeCurrencyModal, curArrayToLoop, onSelectCurrency, selectedObj} = props;
	const systemColorManager = systemColor()

	const currencyClicked = (selectedObj) => {
		onSelectCurrency(selectedObj)
		closeCurrencyModal()
	}

	return <DownModal containerStyle={{...systemColorManager.bg_1}} isOpen={isOpen} onClose={closeCurrencyModal}>
            
	            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
	                <div className="" style={{maxHeight: '50vh', width: '90%', overflowY: "auto" }}>
	                    {curArrayToLoop?.map((item, index) => {

	                    	const bgco = selectedObj?.name == item.name?{...systemColorManager.borders_color, ...systemColorManager.no_bg}:systemColorManager.btn_2

	                    	return <div onClick={() => currencyClicked(item)} style={{...bgco, borderRadius: 15, ...systemColorManager.txt_1}}  className="flexify btn btn-block">
	                    	
	                    	<span style={{}}>{item.name}</span>
	                    	
	                    	<span style={{flex: 1}}></span>

	                    	{item?.image && <img style={{marginRight: 5}} height={20} src={item.image} />}

	                    </div>})}
	                </div>
	            </div>
                    
          </DownModal>

}

