import React, {useState, useEffect} from "react";
import Section from "./layouts/Section";
import {WebLink, SiteWide} from "./customComponents";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import Helper1, {notify, niceDate, money} from '../utility/Helper1';
import {mainStyle, mainColor, header, otherColors} from '../utility/styles';
import CustomAgo from "./customComponents/CustomAgo";

import { useSelector, useDispatch } from 'react-redux';
import {authActions, withdrawalActions} from '../redux/actions';
import { userSelect, allRemoteSettingsSelect, homeScreenStatSelect } from '../redux/selectors/index';
import LoadingOverlay from 'react-loading-overlay';
import {Images} from "./customComponents";
import GoogleMapReact from 'google-map-react';
import {RiUser4Line, RiMore2Fill} from "react-icons/ri";




function Map() {    

    const userData = useSelector(userSelect);
    const site_settings = useSelector(allRemoteSettingsSelect);
    const finance = useSelector(homeScreenStatSelect);
    const dispatch = useDispatch();

    const [latitude, setLatitude]   = useState("");
    const [longitude, setLongitude]   = useState("");
    const [apiKey, setAPiKey]   = useState("");
    const [show, setShow]   =  useState(false)


    useState(() => {
        setLatitude(parseFloat(site_settings?.site_settings?.googleMapLatitude));
        setLongitude(parseFloat(site_settings?.site_settings?.googleMapLongitude));
        setAPiKey(site_settings?.site_settings?.googleMapKey);

        if((site_settings?.site_settings?.googleMapLongitude != "") && (site_settings?.site_settings?.googleMapLatitude !="") && (site_settings?.site_settings?.apiKey !="")){
            setShow(true);
        }
        
    }, [site_settings])


    const defaultProps = {
        center: {
          lat: latitude,
          lng: longitude
        },
        zoom: 11
      };

    const AnyReactComponent = ({ text }) => <div>{text}</div>;

    return (<SiteWide title="Office address" style={{height: '100vh'}}>
        <Section title={'Office address'}>
            


           

            <div style={{ height: '100vh', marginTop: 60, width: '100%', overflowY: 'hidden'}}>
                {show && <GoogleMapReact
                    bootstrapURLKeys={{ key: apiKey }}
                    defaultCenter={defaultProps.center}
                    defaultZoom={defaultProps.zoom}
                  >

                  <AnyReactComponent
                      lat={latitude}
                      lng={longitude}
                      text="Marker"
                    />
                  </GoogleMapReact>}

            </div>

               

        </Section>
        </SiteWide>
    );

}

export default Map;