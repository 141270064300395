import React, {Component} from "react";
import Colors from '../../utility/Colors';
import {systemColor} from '../../utility/styles';
import {menuItems} from '../../utility/menuLists';
import Navbar from "./Navbar";

import MobileBreaker from "./MobileBreaker";

import {WebLink, SiteWide, Images} from "../customComponents";
import {useLocation} from "react-router-dom";



function Footer() {



        const { pathname } = useLocation();

        const systemColorManager = systemColor()

        const isPage = (item) => item.address == pathname || 
            (item.address == '/mine' && (pathname == '/signup' || pathname == '/signin' || pathname == '/reset-password'))

        return (
            <>

                <div className="footer-area">
                    

                    <div className="conainer">
                        <div className="footer-bottom" style={{}}>

                            <MobileBreaker>
                                
                                <div  className="main-footer" style={{}}>

                                    <ul style={{...systemColorManager.footer, display: 'flex', color: '#cecece'}}>
                                        
                                        {menuItems.map((item, index) => {

                                            return <li className="each-bottom-menu" style={{borderRadius: 50}}>
                                            <WebLink style={{...systemColorManager.txt_1}} className="home-clicked" to={item.address}>

                                                <Images height={isPage(item)?"10px":"30px"} src={item.img} alt="menu icon" />
                                                
                                                {(isPage(item) || 1==1) && <p style={{marginTop: 5}}>{item.title}</p>}

                                            </WebLink>

                                        </li>})}

                                    </ul>
                                    
                                </div>

                            </MobileBreaker>

                        </div>
                    </div>


                </div>





            </>
        );

}

export default Footer;