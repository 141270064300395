import { all,call, put, takeEvery } from "redux-saga/effects";
import * as actionTypes from "../actions/actionTypes";

import APIRequests from '../../utility/APIRequests';




function* onPOSTTrade(action) {
  try {
    const response = yield (new APIRequests).runPost(action.routeToCall, action.param);

    if (response.code == 1) {
      
      

      if(response?.data?.trade){
        yield put({ type: actionTypes.TRADE_SAVE, data: response.data.trade });
      }
      if(response?.data?.user){

        response.data.user.token = response.access_token;

        yield put({ type: actionTypes.LOGIN_SUCCESS, user: response.data.user });
        
      }
      if(response?.data?.finance){
        yield put({ type: actionTypes.HOME_SCREEN_STAT, homescreenstat: response.data.finance });
      }
      if(response?.data?.settings){
        yield put({ type: actionTypes.SAVE_REMOTE_SITE_SETTINGS, settings: response.data.settings });
      }
    }

    return action.callback?.(response);
  } catch (error) {

    return action.callback?.(error.response ?? error.message);
  }
}


function* onGETTrade(action) {
  try {
    const response = yield (new APIRequests).runGet(action.routeToCall);

    if (response.code == 1) {
      yield put({ type: actionTypes.TRADE_SAVE, data: response.data.trade });
      response.data.user.token = response.access_token;
      // yield put({ type: actionTypes.LOGIN_SUCCESS, user: response.data.user });
      yield put({ type: actionTypes.HOME_SCREEN_STAT, homescreenstat: response.data.finance });
      yield put({ type: actionTypes.SAVE_REMOTE_SITE_SETTINGS, settings: response.data.settings });
    }

    return action.callback?.(response);
  } catch (error) {

    return action.callback?.(error.response ?? error.message);
  }
}


function* onGetDummy(action) {
  try {
    const response = yield (new APIRequests).runGet(action.routeToCall);

    return action.callback?.(response);
  } catch (error) {

    return action.callback?.(error.response ?? error.message);
  }
}



function* onDummyPost(action) {
  try {
    const response = yield (new APIRequests).runPost(action.routeToCall, action.param);

    return action.callback?.(response);
  } catch (error) {

    return action.callback?.(error.response ?? error.message);
  }
}




function* watchOnPOSTTrade() {
  yield takeEvery(actionTypes.TRADE_POST, onPOSTTrade);
}



function* watchOnGETTrade() {
  yield takeEvery(actionTypes.TRADE_GET, onGETTrade);
}



function* watchOnDummyTrade() {
  yield takeEvery(actionTypes.TRADE_DUMMY, onGetDummy);
}

function* watchOnDummyTradePost() {
  yield takeEvery(actionTypes.TRADE_DUMMY_POST, onDummyPost);
}




export default function* tradeSagas() {
  yield all([watchOnPOSTTrade(), watchOnGETTrade(), watchOnDummyTrade(), watchOnDummyTradePost()]);
}
