import * as actionTypes from "../actions/actionTypes";

const initialState = {
  depositdata: null,
};

export default (state = initialState, action = {}) => {

  switch (action.type) {
    case actionTypes.SAVE_DEPOSIT_DATA:
      return {
        ...state,
        depositdata: action.data,
      };


      
    default:
      return state;
  }
};
