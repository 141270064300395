import React, {useState, useEffect} from "react";
import Section from "../layouts/Section";
import CustomAgo from "../customComponents/CustomAgo";
import {WebLink, SiteWide, Images} from "../customComponents";
import {mainStyle, mainColor, header} from '../../utility/styles';
import Modal from 'react-modal';
import { useSelector, useDispatch } from 'react-redux';
import {exchangeActions, authActions} from '../../redux/actions';
import { userSelect, allRemoteSettingsSelect, homeScreenStatSelect, exchangeSelect } from '../../redux/selectors/index';
import Helper1, {notify, styles, money, customModalStyles, moneyStatus, usefulColors} from '../../utility/Helper1';
import { useHistory, useLocation } from "react-router-dom";
import {systemColor} from '../../utility/styles';

function AllKyc() {
        const systemColorManager = systemColor()

    const dispatch = useDispatch();
    const location = useLocation();

    const [loading, setloading]   = useState(false);
    const [loadingText, setLoadingText]   = useState('');
    const [messageObject, setMessageObject]   = useState([]);

    const [kycFile, setKycFile]   = useState('');
    const [firstname, setFirstname]   = useState('');
    const [lastname, setLastname]   = useState('');
    const [address, setAddress]   = useState('');
    const [dob, setDob]   = useState('');
    const [sex, setSex]   = useState('');

    const userData = useSelector(userSelect);
    const finance = useSelector(homeScreenStatSelect);


    const loadAllHomePageData = () => {

        if((userData?.emailVerification ?? 0) == 0){ return; }

        dispatch(authActions.onGetHomePageData("user/load/homepage", response => {

        }))

    }

    React.useEffect(() => {

        let timing = setTimeout(() => {
            loadAllHomePageData();
        }, 10000)
        
        return () => clearTimeout(timing);
    }, [])

    return (
        <SiteWide overlayText={loadingText} loading={loading} title={"All Applications"}>
                
            <div className="page-wrapper">

                <div className="applications" style={{padding: 10, backgroundColor: 'transparent'}}>
                    

                    <div className="applications-preview" style={{}}>
                        

                        {userData?.kyc?.map((kycItem, kycIndex) => <div className="preview-card" style={{border: '1px solid grey', marginBottom: 10}}>
                            <div className="applicant-details">
                                <div className="applicant-name">{kycItem.firstname} {kycItem.lastname}</div>
                                <div className="application-status accepted" style={{...systemColorManager.txt_1}}>{moneyStatus()[kycItem.status]}</div>
                            </div>
                            <div className="applicant-address" style={{...systemColorManager.txt_1}}>
                                {kycItem.address}
                            </div>
                            <div className="application-date">
                                <div className="application-id">
                                    <p className="head">Application ID</p>
                                    <p className="text-whie" style={{...systemColorManager.txt_1}}>#{kycItem.txn_Id}</p>
                                </div>
                                <div className="last-updated">
                                    <p className="head">Date of birth</p>
                                    <p className="text-whte" style={{...systemColorManager.txt_1}}>{kycItem.dob}</p>
                                </div>
                            </div>
                        </div>)}
                        

                    </div>
                </div>

            </div>


        </SiteWide>
    );

}

export default AllKyc;