import React, {useState} from "react";
import Section from "./layouts/Section";
import {WebLink, SiteWide} from "./customComponents";
import { useSelector, useDispatch } from 'react-redux';

import {authActions} from '../redux/actions';
import { userSelect, allRemoteSettingsSelect, homeScreenStatSelect} from '../redux/selectors/index';
import Helper1, {notify, money, customNormalModalStyles} from '../utility/Helper1';
import {homepage} from '../utility/APIRequests';


import Countdown from 'react-countdown';

import {CopyToClipboard} from 'react-copy-to-clipboard';
import copy from 'copy-to-clipboard';

import Modal from 'react-modal';
import {staticCountries} from '../utility/countries';
import {Images} from "./customComponents";
import Slider from "react-slick";

import {systemColor} from '../utility/styles';
import { useHistory } from 'react-router-dom'; // version 5.2.0


import { MdVerifiedUser, MdOutlineVpnLock, MdGroupWork, MdLockClock, MdArrowRightAlt } from "react-icons/md";


function PersonalInformation(){

    const userData = useSelector(userSelect);
    const site_settings = useSelector(allRemoteSettingsSelect);
    const finance = useSelector(homeScreenStatSelect);
    const history = useHistory();

    const [financeEditing, setFinanceEditing]   = useState(0);
    const [walletModal, setWalletModal]   = useState(false);
    const [withdrawalPinModal, setWithdrawalPinModal]   = useState(false);
    const [withdrawalPin, setWithdrawalPin]   = useState('');
    const [emailOTP, setEmailOTP]   = useState('');
    const [phoneModal, setPhoneModal]   = useState(false);
    
    const [phone, setPhone]   = useState(userData?.phone ?? '');
    const [phoneCode, setPhoneCode]   = useState('...');
    const [country, setCountry]             = useState(userData?.country ?? ''); //'156'

    const [passwordModal, setPasswordModal]   = useState(false);
    const [loading, setloading]   = useState();
    const [loadingText, setLoadingText]   = useState('Loading...');

    const [password, setPassword]       = useState('');

    const dispatch = useDispatch();

    const hiddenKYCInput = React.useRef(null);

    
    const systemColorManager = systemColor()

    React.useEffect(() => {
        changeCountry(country)
    }, [])

    const changeCountry = (countryId) =>{
        let selectedCountry = staticCountries.filter(e => e.id == countryId) 
        setCountry(countryId);
        let countryPhoneCode = selectedCountry?.[0]?.phonecode;
        setPhoneCode(countryPhoneCode);

    }



    const loadAllHomePageData = () => {

        if((userData?.emailVerification ?? 0) == 0){ return; }

        dispatch(authActions.onGetHomePageData("user/load/homepage", response => {

        }))

    }



    React.useEffect(() => {

        loadAllHomePageData();
        

    }, [])



    const dashMenu = [
        
        {
            icon: MdVerifiedUser,
            name: 'Account Nickname',
            color: '#392',
            value: userData.username,
            show: true,
        },
        {
            icon: MdGroupWork,
            name: 'Email ID',
            color: '#392',
            value: userData.email,
            show: true,
        },
        {
            icon: MdOutlineVpnLock,
            name: 'User Country',
            color: '#392',
            value: userData.countryName,
            show: true,
        },

        {
            icon: MdLockClock,
            name: 'Security Center',
            color: '#392',
            to: () => history.push('/security-center'),
            show: true 
        }
        


        
        
    ]



    return (
        <SiteWide id="main" title={`Information`}>

            <div className="transaction-details">
                




                <div className="container" style={{}}>

                    <div className="row">
                        <div className="col-xlg-12 col-md-12 col-12" style={{marginBottom: 200}}>
                            <div style={{}} className="top-reportF">


                                    

                                    {dashMenu.map((item, index) => {

                                        if(!item.show){ return <></> }

                                        const Icon = item.icon;

                                        return <WebLink  style={{...systemColorManager.bg_2, marginBottom: 10, padding: 20, display: 'flex', justifyContent: 'space-between', borderRadius: 10}} title={item.name} onClick={item.to}>

                                            
                                            <div style={{display: 'flex'}}>
                                                <span><Icon color={systemColorManager.txt_singly_3} size="24"/> </span>
                                                <h6 className="" style={{...systemColorManager.txt_1, marginLeft: 20}}>{item.name}</h6>
                                            </div>

                                            <div style={{...systemColorManager.txt_2}}>
                                                {item.value && <span>{item?.value}</span>}
                                                {item.to && <div style={{width: 20, height: 20, borderRadius: 20}}><MdArrowRightAlt size={20} color={systemColorManager.txt_singly_3} /></div>}
                                            </div>


                                        </WebLink>})}




                            </div>
                        </div>
                    </div>

                </div>



            </div>

        </SiteWide>
    );

}

export default PersonalInformation;
