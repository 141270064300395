import React, {Component, useState, useEffect} from "react";

import Navbar from "../layouts/Navbar";
import {WebLink, SiteWide} from "../customComponents";
import NoData from "../customComponents/NoData";

import Section from "../layouts/Section";
import {useHistory, useLocation, useParams} from 'react-router-dom';

import {Images} from "../customComponents";
import Colors from '../../utility/Colors';
import Helper1, {byNextDate, notify, styles, money, formatTime, formatDate} from '../../utility/Helper1';

import {footballActions} from '../../redux/actions';
import { useSelector, useDispatch } from 'react-redux';
import { allDepositSelect, userSelect, allRemoteSettingsSelect, footballSelect, homeScreenStatSelect } from '../../redux/selectors/index';
import CustomAgo from "../customComponents/CustomAgo";

import {systemColor} from '../../utility/styles';
import { AiFillQuestionCircle, AiFillClockCircle, AiFillWallet } from "react-icons/ai";
import EachFixture from "./EachFixture"
import PaginatedItems from "../customComponents/pagination/PaginatedItems";
import Slider from "react-slick";
import copy from 'copy-to-clipboard';

import DownModal from "../layouts/DownModal";




const nextMatch = {
    slidesToShow: 2.5,
    slidesToScroll: 2,
    dots: false,
    infinite: false,
}



function ActiveMatches() {

    const dispatch = useDispatch();

    const userData                          = useSelector(userSelect);
    const site_settings                     = useSelector(allRemoteSettingsSelect);
    const [loadingText, setLoadingText]     = useState('Fetching games...');
    const footballData                      = useSelector(footballSelect);

    const [loading, setloading]             = useState(true);
    
    const [userGames, setUserGames]   = useState(footballData?.user_games);

    const systemColorManager = systemColor()

    useEffect(() => {

        setUserGames(footballData?.user_games)

    }, [footballData?.user_games])

    useEffect(() => {

        fetchUserGames()

    }, [])


    const fetchUserGames = () => {
        dispatch(footballActions.onGetUserGames("football/user/loadbets", response => {

            setloading(false)

        }))
    }


    const copyFixtureId = (itemObj) => {
        copy(itemObj.api_fixture_id)
        notify('Fixture ID copied', 1)
    }

    const betInfo = (objItem) => {
        notify(`Your Bet on ${objItem.teams_home_name} vs ${objItem.teams_away_name} was a ${objItem.loseOrWin == 0?'lose':'win'}. Total return on bet is ${money(objItem?.finalReward, objItem?.currency)}`, objItem.loseOrWin)
    }


    const deleteGame = (objToDetete) => {

        dispatch(footballActions.onGetUserGames(`football/user/deleteBet/${objToDetete.id}`, response => {

            notify(response.msg, response.code)
            setloading(false)

        }))
    }


    const sortedGameTime = userGames?.sort((a, b) => new Date(b.time_played) - new Date(a.time_played));

    function Template({ currentItems }){

        return (
            <>

                {currentItems && currentItems.map((item) => <div className="ba-bill-pay-inner" style={{position: 'relative', ...systemColorManager.borders_color, ...systemColorManager.bg_1}}>
                    
                    <div className="cursor-pointer" style={{position: 'absolute', top: 0, right: 0, backgroundColor: systemColorManager.borders_singly_color}}>
                        {(item?.refunded ?? '') == 0 && <small onClick={() => copyFixtureId(item)} className="prem" style={{...systemColorManager.txt_1, padding: 5}}>{item.api_fixture_id}</small>}
                        {(item?.refunded ?? '') == 1 && <small className="prem" style={{...systemColorManager.txt_1, padding: 5}}>Game Cancelled</small>}
                    </div>

                    <div className="cursor-pointer" style={{position: 'absolute', top: 0, left: 0, backgroundColor: systemColorManager.borders_singly_color}}>
                        <small className="prem" style={{...systemColorManager.txt_1, padding: 5}}>Bet {item?.['home-scores']}:{item?.['away-scores']}</small>
                    </div>


                    <div className="ba-single-bill-pay">

                        <div className="thumb" style={{...systemColorManager.no_bg, border: '0px solid #000'}}>
                            <Images height={40} source={item?.league_logo} alt="img" />
                        </div>
                        <div className="details" style={{width: '100%'}}>
                            <h5 style={{display: 'flex'}}>
                                <span className="prem" style={{...systemColorManager.txt_1}}>
                                    {item.teams_home_name} vs {item.teams_away_name} 
                                    <badge style={{marginLeft: 4}} className={(item?.refunded ?? '') == 1?'badge badge-danger':'badge badge-info'}>{(item?.refunded ?? '') == 0?item.fixture_status_short:'CANCELLED'}</badge>
                                    {item?.awardedWinning == 1 && (item?.refunded ?? '') == 0 && <badge style={{marginLeft: 4}} className="badge badge-success">{item?.['goals_home']}:{item?.['goals_away']}</badge>}
                                </span>
                                <span style={{marginLeft: 'auto'}}>
                                    
                                </span>
                            </h5>
                            <p style={{display: 'flex'}}>
                                <span className="flexify" style={{...systemColorManager.txt_1}}>
                                    <AiFillWallet />&nbsp;&nbsp;{money(item?.amount, item?.currency)}&nbsp;&nbsp;

                                    <AiFillClockCircle />

                                    &nbsp;&nbsp;{formatDate(item?.time_played)} {formatTime(item?.time_played)}
                                    &nbsp;&nbsp;

                                    {(new Date(item.fixture_datetime) > new Date()) && <span onClick={() => window.confirm('Are you sure you want to delete this?') && deleteGame(item)} className="cursor-pointer text-danger">Delete</span>}

                                </span>
                                <div style={{marginLeft: 'auto', ...systemColorManager.txt_1}}>
                                    {item?.status >=2 && <span>Status</span>}
                                    {item?.status <2 && <WebLink style={{...systemColorManager.txt_1, ...systemColorManager.btn_2, padding: 4, borderRadius: 4}} className="" to={"/deposit"}>Manage</WebLink>}
                                </div>
                            </p>
                            {/*<p>Fixture ID: </p>*/}
                        </div>

                        

                    </div>

                    {item?.awardedWinning == 1 && <div className="amount-inner" style={{borderTop: '0.1px dashed #eee'}}>
                        <div style={{display: 'flex', justifyContent: 'space-between'}}>
                            <h5 style={{...systemColorManager.txt_1}}>
                                {item?.refunded == 0?'Final reward':'Amount refunded'}
                            </h5>
                            <div className="flexify" style={{...systemColorManager.txt_1}}>

                                <AiFillQuestionCircle className="cursor-pointer" onClick={() => betInfo(item)} size={20} color={item?.loseOrWin == 1?'green':'red'}/>

                                <span style={{marginLeft: 5}}>{money(item?.refunded == 0?item?.finalReward:item?.refundAmount, item?.currency)}</span>
                            </div>

                        </div>
                    </div>}

                </div>)}


            </>)
        
    }



    return (

        <SiteWide loading={loading} overlayText={loadingText} title={'My Games'}>
            
            

            {(userGames?.length ?? 0) > 0 && <PaginatedItems Template={Template} arrayOfDataToDisplay={sortedGameTime} itemsPerPage={10} />}

            {(userGames?.length ?? 0) == 0 && <NoData type="bet game" />}


        </SiteWide>
    );
}

export default ActiveMatches;
