import { all } from "redux-saga/effects";
import authSagas from "./auth";
import fxSagas from "./fx";
import depositSagas from "./deposit";
import withdrawalSagas from "./withdrawal";
import tradeSagas from "./trade";
import notificationSagas from "./notification";
import exchangeSagas from "./exchange";
import footballSagas from "./football";



export default function* rootSaga() {
  yield all([
    authSagas(),
    fxSagas(),
    depositSagas(),
    withdrawalSagas(),
    tradeSagas(),
    notificationSagas(),
    exchangeSagas(),
    footballSagas()
  ]);
}
