import * as actionTypes from "./actionTypes";

export const onLoadAll = (param, routeToCall, callback = () => {}) => {
  return {
    param,
    routeToCall,
    type: actionTypes.GET_ALL_FIXTURE,
    callback,
  };
};

export const onSubmitBet = (param, routeToCall, callback = () => {}) => {
  return {
    param,
    routeToCall,
    type: actionTypes.SUBMIT_BET_REQUEST,
    callback,
  };
};

export const onGetRecents = (routeToCall, callback = () => {}) => {
  return {
    routeToCall,
    type: actionTypes.LOAD_RECENT_BETS,
    callback,
  };
};

export const onGetUserGames = (routeToCall, callback = () => {}) => {
  return {
    routeToCall,
    type: actionTypes.LOAD_USER_BETS,
    callback,
  };
};
