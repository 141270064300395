import * as actionTypes from "../actions/actionTypes";

const initialState = {
  homescreenstat: null,
};

export default (state = initialState, action = {}) => {
  switch (action.type) {

    case actionTypes.HOME_SCREEN_STAT:
      return {
        ...state,
        homescreenstat: action.homescreenstat,
      };

    case actionTypes.CLEAR_HOME_SCREEN_STAT:
      return {
        ...state,
        homescreenstat: null,
      };

    default:
      return state;
  }


};