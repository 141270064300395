import React, {useState, useEffect} from "react";
import Section from "../layouts/Section";
import {WebLink, SiteWide} from "../customComponents";
import { useSelector, useDispatch } from 'react-redux';

import {authActions} from '../../redux/actions';
import { userSelect, allRemoteSettingsSelect, homeScreenStatSelect} from '../../redux/selectors/index';
import Helper1, {notify, money, customNormalModalStyles} from '../../utility/Helper1';
import {homepage} from '../../utility/APIRequests';


import Countdown from 'react-countdown';

import copy from 'copy-to-clipboard';
import {staticCountries} from '../../utility/countries';
import {Images} from "../customComponents";
import Slider from "react-slick";
import { RiFileCopyLine, RiPhoneLockFill, RiBankFill,RiStackshareLine, RiWallet3Fill, RiArrowGoBackLine, RiEarthLine, RiLockUnlockLine, RiAccountBoxFill, RiSecurePaymentLine, RiListOrdered, RiLockPasswordFill, RiLoginBoxFill, RiMouseLine } from "react-icons/ri";

import {systemColor} from '../../utility/styles';
import parse from 'html-react-parser';

import ChatDelegator from './ChatDelegator'



function LiveChat({history}){

    const userData = useSelector(userSelect);
    const site_settings = useSelector(allRemoteSettingsSelect);
    const finance = useSelector(homeScreenStatSelect);

    const systemColorManager = systemColor()
    
    const [selectedLevel, setSelectedLevel]   = useState(1);
    const [invitationCodeModal, setInvitationCodeModal]   = useState(false);

    const dispatch = useDispatch();

    const copyRefLink = () => {
        notify('Referral Link Copied', 1)
    }



    const toggleInviteModal = () => {
        setInvitationCodeModal(!invitationCodeModal)
    }


    const copyThis = (itemToCopy) => {
        copy(itemToCopy)
        notify('Copied', 1)
    }

    return (
        <SiteWide title={`Live chat`} removeFooter>
                
            
            <Images style={{borderRadius: 10}} source={site_settings.site_settings?.livechat_image_banner} alt="Live chat" />


            <div style={{marginTop: 20, ...systemColorManager.txt_1}}>
                {parse(site_settings.site_settings?.livechat_descriptor)}
            </div>



            <ChatDelegator />

        </SiteWide>
    );

}

export default LiveChat;