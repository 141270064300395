import React, {useState, useEffect} from "react";
import Section from "../layouts/Section";
import {WebLink, SiteWide} from "../customComponents";
import { useSelector, useDispatch } from 'react-redux';

import {authActions} from '../../redux/actions';
import { userSelect, allRemoteSettingsSelect, homeScreenStatSelect} from '../../redux/selectors/index';
import Helper1, {notify, money, customNormalModalStyles} from '../../utility/Helper1';
import {homepage} from '../../utility/APIRequests';


import Countdown from 'react-countdown';

import {CopyToClipboard} from 'react-copy-to-clipboard';
import {staticCountries} from '../../utility/countries';
import {Images} from "../customComponents";
import Slider from "react-slick";
import { RiPhoneLockFill, RiBankFill,RiStackshareLine, RiWallet3Fill, RiArrowGoBackLine, RiEarthLine, RiLockUnlockLine, RiAccountBoxFill, RiSecurePaymentLine, RiListOrdered, RiLockPasswordFill, RiLoginBoxFill, RiMouseLine } from "react-icons/ri";
import {systemColor} from '../../utility/styles';


function Wallet({history}){

    const userData = useSelector(userSelect);
    const site_settings = useSelector(allRemoteSettingsSelect);
    const finance = useSelector(homeScreenStatSelect);

    const [withdrawalPin, setWithdrawalPin]   = useState('');
    const [emailOTP, setEmailOTP]   = useState('');

    
    const [loading, setloading]   = useState();
    const [loadingText, setLoadingText]   = useState('Loading...');

    const dispatch = useDispatch();

    const systemColorManager = systemColor()



    const doWithdrawalPinChange = (e) => {

        e.preventDefault();
        setloading(true)
        setLoadingText('Changing Withdrawal PIN...')

        dispatch(authActions.onLogin(`account/withdrawal-pin/change`, {emailOTP, withdrawalPin}, response => {
            notify(response.msg, response.code)
            setloading(false);
            if(response.code == 1){  setWithdrawalPin(''); }
        }),
        );

    }


    const getNewEmailOTP = () => {

        dispatch(authActions.onLogin(`account/send-email-otp/withdrawal-pin`, {}, response => {

        }),
        );

    }


    useEffect(() => {
        getNewEmailOTP();
    }, [])

    return (
        <SiteWide title={`Manage security PIN`}>
                
                <div className="cotainer">

                    <form onSubmit={doWithdrawalPinChange} className="input-container"style={{...systemColorManager.no_bg}}>

                        <div>

                            <div class="single-input-wrap">

                                <span className="new-input-label" style={{...systemColorManager.txt_1}}>New security PIN</span>
                                <input maxLength={4} autoComplete="new-password" placeholder="Security PIN" onChange = {e => setWithdrawalPin(e.target.value) } required="" className="form-field form-control" type="password" style={{...systemColorManager.bg_3}} />
                                
                            </div>



                            <div className="single-input-wrap">
                                <span className="new-input-label" style={{...systemColorManager.txt_1}}>OTP (from email)</span>
                                <input type={"text"} className="form-field form-control" onChange={e => setEmailOTP(e.target.value) } required placeholder="OTP" style={{...systemColorManager.bg_3}}/>
                            </div>



                        </div>


                        <div>

                        <Countdown
                            zeroPadTime={2}
                            key={new Date(userData?.withdrawalPinResetTime ?? 0)}
                            date={new Date(userData?.withdrawalPinResetTime ?? 0)}
                            renderer={({ hours, minutes, seconds, completed }) => {
                              if (completed) {
                                return <span className="text- pull-right" onClick={getNewEmailOTP}>Request OTP</span>
                              } else {
                                return <span className="text- pull-right">Resend OTP after {minutes}:{seconds} seconds</span>;
                              }
                            }}
                          />
                            <button type="submit" disabled={loading} className="btn mt-40 btn-block btn-danger" style={{...systemColorManager.btn_3}}>
                            Save </button>
                        </div>
                        
                    </form>

                </div>


        </SiteWide>
    );

}

export default Wallet;