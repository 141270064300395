import React, {useState, useEffect} from "react";
import {WebLink, SiteWide} from "../customComponents";
import { useSelector, useDispatch } from 'react-redux';

import {authActions} from '../../redux/actions';
import { userSelect, allRemoteSettingsSelect, homeScreenStatSelect} from '../../redux/selectors/index';
import Helper1, {notify, money, customNormalModalStyles} from '../../utility/Helper1';
import {homepage} from '../../utility/APIRequests';
import DownModal from "../layouts/DownModal";


import Countdown from 'react-countdown';

import copy from 'copy-to-clipboard';
import {staticCountries} from '../../utility/countries';
import {Images} from "../customComponents";
import Slider from "react-slick";
import { RiFileCopyLine, RiPhoneLockFill, RiBankFill,RiStackshareLine, RiWallet3Fill, RiArrowGoBackLine, RiEarthLine, RiLockUnlockLine, RiAccountBoxFill, RiSecurePaymentLine, RiListOrdered, RiLockPasswordFill, RiLoginBoxFill, RiMouseLine } from "react-icons/ri";
import {systemColor} from '../../utility/styles';


function GoogleAuth({history}){

    const userData = useSelector(userSelect);
    const site_settings = useSelector(allRemoteSettingsSelect);
    const finance = useSelector(homeScreenStatSelect);

    const [accessCode,  setAccessCode]      = useState('');
    const [emailOTP,    setEmailOTP]        = useState('');
    const [authModal,    setAuthModal]        = useState(false);


    
    const [loading, setloading]             = useState();
    const [loadingText, setLoadingText]     = useState('Loading...');

    const dispatch = useDispatch();
    const systemColorManager = systemColor()

    
    const toggleAuthModal = () => {
        setAuthModal(!authModal)
    }

    const activateGoogleAuth = (e) => {

        e.preventDefault();
        setloading(true)
        setLoadingText('Activating...')

        dispatch(authActions.onLogin(`account/google-auth/activate`, {google2fa:accessCode}, response => {
            notify(response.msg, response.code)
            setloading(false);

            if(response.code == 1){
                toggleAuthModal()
            }
            
        }),
        );

    }


    const deActivateGoogleAuth = (e) => {

        e.preventDefault();
        setloading(true)
        setLoadingText('Deactivating...')

        dispatch(authActions.onLogin(`account/google-auth/deactivate`, {google2fa:accessCode}, response => {
            notify(response.msg, response.code)
            setloading(false);

            if(response.code == 1){
                toggleAuthModal()
            }

        }),
        );

    }
   


    return (
        <SiteWide title={`Google Authenticator`}>
                
                <div className="">



                        {userData?.use_google_auth == 0 && <div style={{margin: 10, padding: 10}}>


                            {userData?.use_google_auth == 0 && <p className="text-center">
                                
                                <Images source={userData?.google2faImage} />
                                
                                <br/><br/><br/>

                                <h6 style={{...systemColorManager.txt_1}}>Setup Key</h6>

                                <h4 className="text-info flexify cursor-pointer" onClick={() => {copy(userData?.google2FASecret??''); notify('Copied', 1);}}><span>{userData?.google2FASecret}</span> <RiFileCopyLine /></h4>

                                <br/>

                            </p>}


                            <p>
                                <ol>

                                    <li>
                                        Download Google authenticator for android or Google authenticator for Apple
                                    </li>
                                    <li>
                                        Scan the QRcode or enter the provided setup key above in your google authenticator app.
                                    </li>
                                    
                                </ol>
                            </p>

                            <button onClick={toggleAuthModal} style={{...systemColorManager.btn_2, ...systemColorManager.txt_1}} className="cursor-pointer btn btn-block">Supply Google Access Code</button>

                            <DownModal containerStyle={{...systemColorManager.bg_1}} isOpen={authModal} onClose={toggleAuthModal}>
                
                                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
                                    <div className="" style={{maxHeight: 'auto', width: '90%'}}>

                                        <div style={{paddingBottom: 5}}>
                                            <div class="single-input-wrap">
                                                <span className="new-input-label" style={{...systemColorManager.txt_1}}>Access Code</span>
                                                <input autoComplete="new-password" placeholder="Access Code" onChange = {e => setAccessCode(e.target.value) } required="" className="form-field form-control" type="password"  style={{...systemColorManager.borders_color, ...systemColorManager.bg_3}}/>
                                            </div>

                                            <button onClick={activateGoogleAuth} style={{}} className="text-white cursor-pointer btn btn-block" style={{...systemColorManager.btn_3}}>Enable</button>
                                            

                                        </div>

                                    </div>
                                </div>

                            </DownModal>

                        </div>}




                        {userData?.use_google_auth == 1 && <div style={{margin: 10, padding: 10}}>


                            <p>
                                <ol>
                                    <li>
                                        Google auth is active
                                    </li>
                                    <li>
                                        Enter access code from google authicator app below to disable google auth.
                                    </li>
                                    <li>
                                        Or contact support
                                    </li>
                                    
                                </ol>
                            </p>





                            <button onClick={toggleAuthModal} style={{...systemColorManager.btn_2, marginTop: 80, ...systemColorManager.txt_1}} className="cursor-pointer btn btn-block">Supply Google Access Code</button>

                            <DownModal containerStyle={{...systemColorManager.bg_1}} isOpen={authModal} onClose={toggleAuthModal}>
                
                                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
                                    <div className="" style={{maxHeight: 'auto', width: '90%'}}>
                                        <div style={{paddingBottom: 5}}>

                                            <div class="single-input-wrap">

                                                <span className="new-input-label" style={{...systemColorManager.txt_1}}>Access Code</span>
                                                <input autoComplete="new-password" placeholder="Access Code" onChange = {e => setAccessCode(e.target.value) } required="" className="form-field form-control" type="password"  style={{...systemColorManager.borders_color, ...systemColorManager.bg_3}}/>
                                                
                                            </div>

                                            <button onClick={deActivateGoogleAuth} style={{}} className="text-white cursor-pointer btn btn-block" style={{...systemColorManager.btn_3}}>Disable</button>

                                        </div>
                                    </div>
                                </div>

                            </DownModal>


                        </div>}


                </div>



        </SiteWide>
    );

}

export default GoogleAuth;