import React, {useState, useEffect, useRef} from "react";
import Section from "../layouts/Section";
import CustomAgo from "../customComponents/CustomAgo";
import {WebLink, SiteWide, Images} from "../customComponents";
import {mainStyle, mainColor, header, otherColors} from '../../utility/styles';
import Modal from 'react-modal';
import { useSelector, useDispatch } from 'react-redux';
import {authActions} from '../../redux/actions';
import { userSelect, allRemoteSettingsSelect, homeScreenStatSelect, exchangeSelect } from '../../redux/selectors/index';
import Helper1, {notify, styles, money, customModalStyles, customNormalModalStyles, moneyStatus, usefulColors} from '../../utility/Helper1';
import Countdown from 'react-countdown';
import Slider from "react-slick";
import { useHistory } from "react-router-dom";
import { RadioGroup, RadioButton, ReversedRadioButton } from 'react-radio-buttons'
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { RiChat3Fill, RiUpload2Fill, RiImageFill, RiRecordCircleFill, RiArrowGoBackLine } from "react-icons/ri";
import { BsArrowLeftSquare, BsArrowRightSquare } from "react-icons/bs";

import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import {systemColor} from '../../utility/styles';




function Bonus() {


        const systemColorManager = systemColor()

    const dispatch = useDispatch();
    const hiddenFileInput = React.useRef(null);
    const history = useHistory();
    const chooseCurrency2 = useRef();
    const chooseCurrency1 = useRef();

    const [loading, setloading]   = useState(false);
    const [loadingText, setLoadingText]   = useState('');
    const site_settings = useSelector(allRemoteSettingsSelect);

    const userData = useSelector(userSelect);
    const finance = useSelector(homeScreenStatSelect);


    const processBonusWithdrawal = (swappingFrom) => {

        setloading(true);
        setLoadingText('Requesting...');

        dispatch(authActions.onLogin(`withdraw/bonus`, {}, response => {
            notify(response.msg, response.code)
            if(response.code == 1){
                
            }
            setloading(false);
        }))

    }






    return (
        <SiteWide loading={loading} overlayText={loadingText} title={"Deposit Bonus"}>
            

                <div className="container">
                   

                   <div style={{backgroundColor: otherColors.cotpBlue, borderRadius: 10}}>
                        
                        <div>
                            <h3 style={{...systemColorManager.txt_1}}>{site_settings?.operational_settings?.depositBonusHeadline}</h3>
                        </div>


                        <hr />
                        <div  style={{...systemColorManager.txt_1}}>
                            {site_settings?.operational_settings?.depositBonusContent}
                        </div>

                    </div>



                    <div className="container" style={{marginTop: 30, backgroundColor: otherColors.cotpBlue, borderRadius: 10}}>
                        

                        <div>
                                
                                {finance?.map((bonItem, index) => <div className="container"><div className="row" style={{backgroundColor: otherColors.cotpBlue, padding: 10, display: 'flex', justifyContent: 'space-between', flexDirection: 'row'}}>
                                    

                                    <div className="flexify" style={{width: '100%',
                                        backgroundColor: otherColors.header, borderRadius: 6, padding: '12px 3px', alignItems: 'center', border: "0px solid #CDD9ED"}}>
                                        
                                        <h3 style={{color: '#fff'}}>
                                            {money(bonItem?.depositBonus ?? 0, bonItem?.type ?? 0)}
                                        </h3>

                                    </div>
                                    
                                </div></div>)}




                        </div>
                        

                        <br /><br />

                        <button onClick={processBonusWithdrawal} style={{...systemColorManager.btn_2, ...systemColorManager.txt_1}}  className={`btn-block b-white btn cursor-pointer`}>
                            Claim bonus
                        </button>


                    </div>

                </div>



        </SiteWide>
    );

}

export default Bonus;