import { all,call, put, takeEvery } from "redux-saga/effects";
import * as actionTypes from "../actions/actionTypes";



function* onNotify(action) {
  yield put({ type: actionTypes.CHANGE_NOTIFICATION_NOW, msg: action.param });
}



function* watchOnNotify() {
  yield takeEvery(actionTypes.MANAGE_NOTIFICATION, onNotify);
}


export default function* notificationSagas() {
  yield all([watchOnNotify()]);
}
