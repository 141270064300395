import React, {useState, useEffect} from "react";
import Section from "../layouts/Section";
import {WebLink, SiteWide} from "../customComponents";
import { useSelector, useDispatch } from 'react-redux';

import {authActions} from '../../redux/actions';
import { userSelect, allRemoteSettingsSelect, homeScreenStatSelect} from '../../redux/selectors/index';
import Helper1, {notify, money, customNormalModalStyles} from '../../utility/Helper1';
import {homepage} from '../../utility/APIRequests';


import Countdown from 'react-countdown';

import copy from 'copy-to-clipboard';
import {staticCountries} from '../../utility/countries';
import {Images} from "../customComponents";
import Slider from "react-slick";
import { RiFileCopyLine, RiPhoneLockFill, RiBankFill,RiStackshareLine, RiWallet3Fill, RiArrowGoBackLine, RiEarthLine, RiLockUnlockLine, RiAccountBoxFill, RiSecurePaymentLine, RiListOrdered, RiLockPasswordFill, RiLoginBoxFill, RiMouseLine } from "react-icons/ri";

import {systemColor} from '../../utility/styles';
import parse from 'html-react-parser';


import Drift from 'react-drift-web'
import TawkMessengerReact from '@tawk.to/tawk-messenger-react';
// import { Freshchat } from 'reactjs-freshchat';
// import 'reactjs-freshchat/dist/index.css'
import FreshChat from 'react-freshchat'

import JivoChatWidget from './JivoChatWidget'
import { Helmet, HelmetProvider } from 'react-helmet-async';
import MobileBreaker from "../layouts/MobileBreaker";



function LiveChat({history}){

    const userData = useSelector(userSelect);
    const site_settings = useSelector(allRemoteSettingsSelect);
    const finance = useSelector(homeScreenStatSelect);
    
    const useThisChat = site_settings.site_settings?.livechat_to_use ?? ''


    return (<div style={{backgroundColor: 'red', width: '20%'}}>

                {useThisChat == 0 && <TawkMessengerReact propertyId={site_settings.site_settings?.livechat_tawkto_property_id} widgetId={site_settings.site_settings?.livechat_tawkto_widget_id} />}

                {useThisChat == 1 && <Drift appId={site_settings.site_settings?.livechat_drift_id} />}
                
                {useThisChat == 2 && <Helmet>
                        <script
                        src='//fw-cdn.com/2497749/3055249.js'
                        chat='true'
                        widgetId={site_settings.site_settings?.livechat_fresh_chat_id}>
                        </script> }} />
                    </Helmet>}
                
                {useThisChat == 3 && <JivoChatWidget widgetId={site_settings.site_settings?.livechat_jivo_widget_id}/>}

            </div>
    );

}

export default LiveChat;