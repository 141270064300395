import React, {useState} from "react";
import "./index.css";
import {systemColor} from '../../../utility/styles';




export default function NewsTicker(props){

    const bgImage = require('./green.jpg')
    const systemColorManager = systemColor()

    return (



      <div id="tickr-box" style={{...systemColorManager.ticker_main_bg_image}} className="tickerplace">
          {(props.title ?? '') != '' && <div className="tickr-title prem" style={{...systemColorManager.tickerStrip_bg, ...systemColorManager.tickerStrip_text}}>{props.title}</div>}

          <div id="tickr-scroll">
              <ul>

                  {
                      props.newsList.map((newsListItem, newsListItemKey) => {

                        return <li onClick={() => props.eachNewsClicked(newsListItem)} style={{...systemColorManager.tickerStrip_main_text}} className="soccerfont" key={newsListItemKey}>{newsListItem?.subject ?? ""}</li>
                            
                      })
                  }

              </ul>
          </div>

      </div>)

        


}