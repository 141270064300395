import React, {useState, useEffect} from "react";
import Footer from "./layouts/Footer";
import Navbar from "./layouts/Navbar";
import {Link} from "react-router-dom";
import AutoNotification from "./layouts/AutoNotification";
import Section from "./layouts/Section";
import CustomAgo from "./customComponents/CustomAgo";
import {WebLink, SiteWide, Images} from "./customComponents";
import {mainStyle, mainColor, header} from '../utility/styles';
import Modal from 'react-modal';
import { useSelector, useDispatch } from 'react-redux';
import {tradeActions} from '../redux/actions';
import { userSelect, allRemoteSettingsSelect, homeScreenStatSelect, allTradeSelect } from '../redux/selectors/index';
import Helper1, {notify, styles, money, customModalStyles} from '../utility/Helper1';
import { useSnackbar } from 'snackfy';
import Countdown from 'react-countdown';
import Slider from "react-slick";
import { useHistory } from "react-router-dom";



function Asset() {

    const dispatch = useDispatch();

    const [loading, setloading]   = useState(false);
    const [loadingText, setLoadingText]   = useState('');

    const tradesData = useSelector(allTradeSelect);
    const userData = useSelector(userSelect);

    const closeTrade = (centerId) => {
        setloading(true)
        setLoadingText('Closing trade');
        dispatch(tradeActions.onGETTheTrades(`trade/close/${centerId}`, response => {
                setloading(false)
                notify(response?.msg, response?.code);
            })
        )
    }

    return (
        <SiteWide id="main" title={"Trade assets"}>
            <Section  hideHeader={true} overlayText={loadingText} loading={loading} title={'Trade assets'}>
                <div className="goal-area">
                    <div className="container">
                        <div className="section-title">
                            <h3 className="title">Trading Assets</h3>
                            <WebLink className="goal-title" to={'/saving'}>Total - ${tradesData?.data?.reduce((a, b) => Number(a) + Number(b.profit), 0).toFixed(2)}</WebLink>
                        </div>



                        {tradesData?.data?.map((item, index) => {

                            const robotActivated = userData?.robot?.filter((existeItem, index) => item.inTrade >= existeItem.minimum && item.active && item.inTrade <= existeItem.maximum);

                            return <div className="single-goal single-goal-one" style={{border: 0}}>
                            <div className="row">
                                <div className="col-7 pr-0">
                                    <div className="details">
                                        <h6>{money(item.capital, item.type)} Trade {" "}
                                        </h6>  

                                        <span className="main_number text-info" style={{fontSize: 22, marginTop: 25, marginLeft: 13, left: 1}}>{robotActivated?.length > 0 && <small>{robotActivated?.[0]?.robotName} Processing <span className="ellipsisloading"></span></small>}</span>

                                        <p>{item.txn_Id}</p>
                                    </div>
                                </div>
                                <div className="col-5 pl-0">
                                    <div onClick={() => item.active == 1 && window.confirm('Trade will be closed while profit will be taken') && closeTrade(item?.id)} className={`circle-inner circle-inner-one ${item.active == 1?'bg-success':'bg-danger'} cursor-pointer`} style={{borderRadius: 0}}>
                                        <div className="chart-circle" data-value="0.30">
                                            <canvas width="52" height="52" />
                                            <div className="chart-circle-value text-center text-white">
                                                {money(item?.profit, item?.type)}
                                                {item.active == 1 && <small style={{display: 'block'}}>Close Trade</small>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>})}



                    </div>
                </div>
            </Section>
        </SiteWide>
    );

}

export default Asset;