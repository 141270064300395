import * as actionTypes from "../actions/actionTypes";
const initialState = {
  settings: null,
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case actionTypes.SAVE_REMOTE_SITE_SETTINGS:

      return {
        ...state,
        settings: {...action.settings, masterIsLoading: false},
      };


    default:
      return  state;
  }
};
