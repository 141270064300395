import React, {Component, useState, useEffect} from "react";

import {systemColor} from '../../utility/styles';
import {Images} from "../customComponents";
import { AiFillStar, AiOutlineFieldTime, AiOutlineComment, AiOutlineMail } from "react-icons/ai";
import { FaStar, FaChevronRight, FaSun, FaCloudMoon } from "react-icons/fa";
import {formatTime, formatDate} from '../../utility/Helper1';


export default function EachFixture(props) {


    let firstOdd = props?.fixture?.exactScoreOdd?.[props?.fixture?.exactScoreOdd?.length - 1]

    const systemColorManager = systemColor()


    return (<div className="cursor-pointer" style={{marginRight: !props.fromAllPage?'20px':0}}>
            
            <div className="" style={{borderRadius: 10, ...systemColorManager.bg_2}}>

                <div onClick={
                            () => props.fromAllPage?props?.eachIsClicked(props?.fixture):props?.eachIsClicked(firstOdd)
                        } >

                     <div className="" style={{fontWeight: 900, flex: 1, textTransform: 'capitalize'}}>
                        
                        <div style={{display: 'flex', padding: 10, alignItems: 'center'}}>
                            
                            {Number(props?.fixture?.['recommended-game']) === 1 && <span>
                                <AiFillStar color={systemColorManager.txt_singly_1} size={15} />
                            </span>}

                            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end', flex: 1}}>
                                <Images height={10} style={{}} source={props?.fixture?.league_logo} alt={props?.fixture?.league_name} />
                                <span style={{marginLeft: 5, ...systemColorManager.txt_1}}>{props?.fixture?.league_name}</span>
                            </div>

                        </div>

                        <div className="flexify" style={{padding: 20}}>
                            
                            <div className="text-center" style={{flex: 1, display: 'flex', flexDirection: 'column', textAlign: 'center', alignItems: 'center', justifyContent: 'center'}}>

                                <div className="league-img-circle" style={{width: 60, height:60,...systemColorManager.btn_3}}>
                                    <Images height={40} style={{}} source={props?.fixture?.teams_home_logo} alt={props?.fixture?.teams_home_name} />
                                </div>

                                <div style={{marginTop: 10, ...systemColorManager.txt_1}}>{props?.fixture?.teams_home_name}</div>

                            </div>

                            <div className="text-center">
                                {!props.fromAllPage && <div style={{fontSize: 30, ...systemColorManager.txt_1}}>
                                    {firstOdd?.['home-scores']}:{firstOdd?.['away-scores']}
                                </div>}

                                {!props.fromAllPage && <div style={{fontSize: 14, ...systemColorManager.txt_2}}>
                                    {Number(firstOdd?.['percentage-winning'])?.toFixed(1)}%
                                </div>}

                                {props.fromAllPage && <div>
                                    <Images height={25} style={{marginRight: 5}} source={require('../../images/vs.png')} alt={'versus'} />
                                </div>}

                            </div>

                             <div className="text-center" style={{flex: 1, display: 'flex', flexDirection: 'column', textAlign: 'center', alignItems: 'center', justifyContent: 'center'}}>

                                <div className="league-img-circle" style={{width: 60, height:60,...systemColorManager.btn_3}}>
                                    <Images height={40} style={{}} source={props?.fixture?.teams_away_logo} alt={props?.fixture?.teams_away_name} />
                                </div>

                                <div style={{marginTop: 10, ...systemColorManager.txt_1}}>{props?.fixture?.teams_away_name}</div>

                            </div>

                        </div>


                    </div>

                </div>

                <div className="flexify" style={{...systemColorManager.bg_3, paddingLeft: 10, borderBottomLeftRadius: 10}}>
                    
                    <div onClick={
                            () => props.fromAllPage?props?.eachIsClicked(props?.fixture):props?.eachIsClicked(firstOdd)
                        } 
                        className="flexify-left" style={{flex: 1, ...systemColorManager.txt_1}}>
                        <AiOutlineFieldTime size={20} />
                        &nbsp;
                        {formatTime(props?.fixture?.fixture_datetime)} {formatDate(props?.fixture?.fixture_datetime)}
                    </div>

                    {!props.fromAllPage && <div className="cursor-pointer" onClick={() => props.history.push(`fixture/${props?.fixture?.fixture_id}`)} style={{...systemColorManager.btn_2, padding: 10, borderBottomRightRadius: 10}}>
                        More Odds <FaChevronRight size={12} />
                    </div>}

                </div>

            </div>

        </div>
    );
}



