import React, {useState, useEffect} from "react";
import Section from "../layouts/Section";
import CustomAgo from "../customComponents/CustomAgo";
import {WebLink, SiteWide, Images} from "../customComponents";
import {mainStyle, mainColor, header} from '../../utility/styles';
import Modal from 'react-modal';
import { useSelector, useDispatch } from 'react-redux';
import {exchangeActions, authActions} from '../../redux/actions';
import { userSelect, allRemoteSettingsSelect, homeScreenStatSelect, exchangeSelect } from '../../redux/selectors/index';
import Helper1, {notify, styles, money, customModalStyles, moneyStatus, usefulColors} from '../../utility/Helper1';
import { useHistory, useLocation } from "react-router-dom";
import { RadioGroup, RadioButton, ReversedRadioButton } from 'react-radio-buttons'
import { AiOutlineCloudUpload } from "react-icons/ai";
import {systemColor} from '../../utility/styles';

export default function Manage() {

        const systemColorManager = systemColor()

    const dispatch = useDispatch();
    const location = useLocation();

    const [loading, setloading]   = useState(false);
    const [loadingText, setLoadingText]   = useState('');
    const [messageObject, setMessageObject]   = useState([]);

    const [kycFile, setKycFile]   = useState('');
    const [firstname, setFirstname]   = useState('');
    const [lastname, setLastname]   = useState('');
    const [address, setAddress]   = useState('');
    const [dob, setDob]   = useState('');
    const [sex, setSex]   = useState('');

    const userData = useSelector(userSelect);
    const finance = useSelector(homeScreenStatSelect);

    const hiddenKYCInput = React.useRef(null);


    const instantUploadPreview = (file, destID) => {

        let output = document.getElementById(destID);

        // if(!Array.isArray(file)){ 
            // typeof file === 'object'
        if(file.type == undefined){
            output.src = file;
        }else{

            output.src = URL.createObjectURL(file);
            output.onload = function() {
                  URL.revokeObjectURL(output.src) // free memory
                }
        }
    };


    const kycImageSelected = (file) => {
        setKycFile(file)
        instantUploadPreview(file, 'changeImage')
    }

    // uploads
    const progClickFileUpload = () => {
        hiddenKYCInput.current.click();
    }

    const uploadKYC = (file) => {
        

        if(kycFile == ''){ notify('A valid document yet to be selected', 0); return; }

        setloading(true);
        setLoadingText('Uploading KYC...')

        dispatch(authActions.onLogin("kyc/upload", {image:kycFile, firstname, lastname, address, dob, sex}, response => {
                setloading(false)
                notify(response.msg, response.code)
                if(response.code == 1){
                    setFirstname('');
                    setLastname('');
                    setAddress('');
                    setDob('');
                    setSex(0)
                }
            }),
        )
    }


    

    const loadAllHomePageData = () => {

        if((userData?.emailVerification ?? 0) == 0){ return; }

        dispatch(authActions.onGetHomePageData("user/load/homepage", response => {

        }))

    }

    React.useEffect(() => {

        let timing = setTimeout(() => {
            loadAllHomePageData();
        }, 1000)
        
        return () => clearTimeout(timing);
    }, [])


    return (
        <SiteWide overlayText={loadingText} loading={loading} title={"KYC"}>                

                <div className="page-wrapper">


                    <div className="application-details">


                        <div className="application-form">

                        

                            <div className="frm-card">
                                <div className="form-wrapper">
                                    <div className="kyc-upload">

                                        <div className="head" style={{...systemColorManager.txt_1}}>
                                            KYC Doc.
                                        </div>
                                        
                                    </div>
                                    <div className="browse-card">

                                        <div style={{width: '100%'}} className="browse-documents cursor-pointer" onClick={progClickFileUpload}>
                                            <div className="image-selection">
                                                <AiOutlineCloudUpload color={systemColorManager.txt_singly_1} size={40} />
                                            </div>
                                            <div className="image-requirements">
                                                <div className="applicant-details">
                                                    <div style={{...systemColorManager.txt_1}} className="applicant-name">Upload valid document (eg passport, driver license etc)</div>
                                                </div>
                                                <div className="image-condition">
                                                    <p style={{...systemColorManager.txt_1}}> should be in either jpg, jpeg or png format.</p>
                                                </div>
                                            </div>
                                        </div>

                                        <input style={{display: 'none'}} ref={hiddenKYCInput} onChange={(e) => kycImageSelected(e.target.files[0])} type="file" accept=".jpg, .gif, .png, .jpeg"/>

                                    </div>
                                    <div className="address-wrapper" style={{marginTop: 20}}>
                                        <div className="address clearfix">
                                            

                                            <div className="input-wrapper row">
                                                <div className="address-iput col-sm-6 col-12">
                                                    <label  style={{...systemColorManager.txt_1}} for="fname">First Name</label>
                                                    <input  style={{...systemColorManager.bg_3, ...systemColorManager.txt_1}} id="fname" className="auth-input input100" onChange={(e) => setFirstname(e.target.value)} value={firstname} type="text" placeholder="First Name" />
                                                </div>
                                                <div className="address-inut col-sm-6 col-12">
                                                    <label  style={{...systemColorManager.txt_1}} for="lsname">Last Name</label>
                                                    <div className="kyc-input">
                                                        <input  style={{...systemColorManager.bg_3, ...systemColorManager.txt_1}} className="auth-input input100" onChange={(e) => setLastname(e.target.value)} value={lastname} type="text" placeholder="Last Name" />
                                                    </div>
                                                    
                                                </div>
                                                <div className="address-iput col-sm-6 col-12">
                                                    <label for="Address">Address</label>
                                                    <div className="kyc-input">
                                                        <input  style={{...systemColorManager.bg_3, ...systemColorManager.txt_1}} className="auth-input input100" id="Address" onChange={(e) => setAddress(e.target.value)} value={address} type="text" placeholder="Address"/>
                                                    </div>
                                                    
                                                </div>
                                                <div className="address-iput col-sm-6 col-12">
                                                    <label  style={{...systemColorManager.txt_1}} for="dob">Date of birth</label>
                                                    <div className="kyc-input">
                                                        <input  style={{...systemColorManager.bg_3, ...systemColorManager.txt_1}} id="dob" className="auth-input input100" onChange={(e) => setDob(e.target.value)} value={dob} type="date" placeholder="DOB"/>
                                                        <div className="submit">
                                                            <button className="kyc-submit"><i className="fa fa-clock-o"></i></button>
                                                        </div>
                                                    </div>
                                                
                                                </div>
                                                <div className="address-iput col-sm-6 col-12">
                                                    <label  style={{...systemColorManager.txt_1}} for="">Gender</label>
                                                        <div className="kyc-input">
                                                            <div style={{display: 'flex', flexDirection: 'row'}}>


                                                                <RadioGroup onChange={(value) => setSex(value)} horizontal>
                              
                                                                  <RadioButton value="0" pointColor={systemColorManager.borders_singly_color}>
                                                                    Male
                                                                  </RadioButton>
                                                                  
                                                                  <RadioButton value="1" pointColor={systemColorManager.borders_singly_color}>
                                                                    Female
                                                                  </RadioButton>

                                                                </RadioGroup>


                                                            </div>
                                                        </div>
                                                    
                                                </div>



                                            </div>


                                            <div className="col-12" style={{marginTop: 20, padding: 0}}>
                                                <button style={{...systemColorManager.btn_2, ...systemColorManager.txt_1}}  onClick={uploadKYC} className="btn btn-block btn-sm">Submit</button>
                                            </div>

                                            {userData?.kyc?.length > 0 && <div className="text-center" style={{marginTop: 10}}>
                                                 <WebLink to={'/all-kyc'} className="text-info">View Applications</WebLink>
                                            </div>}

                                        </div>


                                    </div>
                                </div>
                                
                            </div>
                        </div>

                    </div>

                </div>

        </SiteWide>
    );

}

