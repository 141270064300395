import React from 'react';
import { Route, Redirect } from 'react-router-dom';

import { useSelector, useDispatch } from 'react-redux';
import { userSelect } from '../redux/selectors/index';

  

// handle the private routes
function UnverifiedMailRoute({ component: Component, ...rest }) {
  const users = useSelector(userSelect);

  return (
    <Route
      {...rest}
      render={(props) => (((users?.emailVerification ?? 1) == 0) || ((users?.phoneVerification ?? 1) == 0)) ? <Component {...props} /> : <Redirect to={{ pathname: '/signin', state: { from: props.location } }} />}
    />
  )
}

export default UnverifiedMailRoute;