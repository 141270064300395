import React, {useState, useEffect} from "react";
import Section from "../layouts/Section";
import {WebLink, SiteWide} from "../customComponents";
import {useHistory} from 'react-router-dom';
import {mainStyle, mainColor, header, otherColors} from '../../utility/styles';

import {authActions} from '../../redux/actions';
import { useSelector, useDispatch } from 'react-redux';
import Helper1, {notify} from '../../utility/Helper1';
import { userSelect, allRemoteSettingsSelect } from '../../redux/selectors/index';
import Countdown from 'react-countdown';
import { RiLoginBoxLine, RiHome7Line, RiArchiveLine, RiBarChart2Line, RiBriefcase4Fill, RiArchiveDrawerLine } from "react-icons/ri";
import AuthHolder from "./AuthHolder";
import {systemColor} from '../../utility/styles';


function UserVerification(){

    const systemColorManager = systemColor()

    const userData = useSelector(userSelect);

    const [emailCodeInput, setEmailCodeInput] = useState('') 
    const [phoneCodeInput, setPhoneCodeInput] = useState('') 
    const [verifyingEmailOrPhone, setVerifyingEmailOrPhone] = useState(0)

    const [fullPageLoading, setFullPageLoading]         = useState(false);
    const history                                       = useHistory();

    const [loading, changeLoading]                      = useState(false);
    const [loadingText, setLoadingText]                 = useState("");


    const dispatch = useDispatch();

    const verifyEmail = (e) => {
        e.preventDefault();

        // setFullPageLoading(true);
        changeLoading(true)
        setLoadingText("Please wait...")

        dispatch(authActions.onSignUp("account/verify_email", {emailCode:emailCodeInput}, response => {

            if(response.code == 1){
                if((userData?.emailVerification == 1) && (userData?.phoneVerification == 1)){
                    history.replace('/personal-information');
                }else{
                    setVerifyingEmailOrPhone(1)
                }
            }

            notify(response.msg, response.code)
            // setFullPageLoading(false);
            changeLoading(false)
            setLoadingText("")
        
        }),
        );

    }


    const resendEmailVerification = (e) => {
        e.preventDefault();

        // setFullPageLoading(true);
        changeLoading(true)
        setLoadingText("Please wait...")
        
        dispatch(authActions.onRefreshSystemData("account/resend_email_verification", 100, {}, response => {
            notify(response.msg, response.code)
            // setFullPageLoading(false);
            changeLoading(false)
            setLoadingText("")
        }),
        );
    }


    const resendPhoneVerification = (e) => {
        e.preventDefault();

        // setFullPageLoading(true);
        changeLoading(true)
        setLoadingText("Please wait...")
        
        dispatch(authActions.onRefreshSystemData("account/resend_phone_verification", 100, {}, response => {
            notify(response.msg, response.code)
            setFullPageLoading(false);
            changeLoading(false)
        }),
        );
    }



    const verifyPhone = (e) => {
        e.preventDefault();

        // setFullPageLoading(true);
        changeLoading(true)
        setLoadingText("Please wait...")

        dispatch(authActions.onSignUp("account/verify_phone", {smsCode:phoneCodeInput}, response => {

            if(response.code == 1){
                history.replace('/personal-information');
            }

            notify(response.msg, response.code)
            // setFullPageLoading(false);
            changeLoading(false)
            setLoadingText("")
        
        }),
        );

    }



    const doLogOutNowNow = () => {
        dispatch(authActions.onLogout())
    }

    useEffect(() => {
        if(userData?.emailVerification == 0){
            setVerifyingEmailOrPhone(0)
        }else if(userData?.phoneVerification == 0){
            setVerifyingEmailOrPhone(1)
        }
    }, [])


    const titleText = verifyingEmailOrPhone == 0?'Verify email':'Verify Phone number';

    const logout = () => {
        return <span style={{...systemColorManager.txt_1}} className="mt-40 cursor-pointer" onClick={doLogOutNowNow} >
            Log out
        </span>
    }

    const logoutContainer = {display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}

    return (
        <SiteWide loading={loading} overlayText={loadingText} hideMenu={true} title={titleText}>
            <AuthHolder customHeadline={titleText} >
                
               

                {verifyingEmailOrPhone == 0 && <>
                    

                    <label className="single-input-wrap">
                        <span className="new-input-label" style={{...systemColorManager.txt_1}}>Code*</span>
                        <input className="auth-input" onChange={e => setEmailCodeInput(e.target.value) } placeholder="Code" required type="text" required style={{...systemColorManager.bg_3, ...systemColorManager.txt_1}}/>
                    </label>


                    <div className="container-login100-form-btn mt-40">
                        <button  onClick={verifyEmail} style={{...systemColorManager.btn_2, ...systemColorManager.txt_1}} className="btn btn-block">
                            Verify Email
                        </button>
                    </div>



                    <div className="verify-btn" style={logoutContainer}>
                        <Countdown
                            zeroPadTime={2}
                            key={new Date(userData?.lastEmailTime)}
                            date={new Date(userData?.lastEmailTime)}
                            renderer={({ hours, minutes, seconds, completed }) => {
                              if (completed) {
                                // Render a completed state
                                // timeUp();
                                return <span style={{...systemColorManager.txt_1}} className="cursor-pointer mt-40" onClick={resendEmailVerification}>Resend OTP</span>
                                // 
                              } else {
                                // Render a countdown
                                return <span style={{...systemColorManager.txt_1}}  className="mt-40">{minutes}:{seconds}</span>;
                              }
                            }}
                        />

                        {logout()}

                    </div>

                </>
                }


                {verifyingEmailOrPhone == 1 && <>

                    <label className="single-input-wrap">
                        <span className="new-input-label" style={{...systemColorManager.txt_1}}>Code*</span>
                        <input className="auth-input" onChange={e => setPhoneCodeInput(e.target.value) } placeholder="Code" required type="text" required style={{...systemColorManager.bg_3, ...systemColorManager.txt_1}}/>
                    </label>


                    <div>
                        <span className="text-white">
                            <Countdown
                                zeroPadTime={2}
                                key={new Date(userData?.lastSMSTime)}
                                date={new Date(userData?.lastSMSTime)}
                                renderer={({ hours, minutes, seconds, completed }) => {
                                  if (completed) {
                                    // Render a completed state
                                    // timeUp();
                                    return <span style={{...systemColorManager.txt_1}} onClick={resendEmailVerification} className="mt-40">Resend Phone OTP</span>
                                    // 
                                  } else {
                                    // Render a countdown
                                    return <span style={{...systemColorManager.txt_1}} className="mt-40">Resend after {minutes}:{seconds}</span>;
                                  }
                                }}
                            />
                        </span>

                    </div>

                    <div className="container-login100-form-btn mt-40">
                        <button  onClick={verifyPhone} style={{...systemColorManager.btn_2, ...systemColorManager.txt_1}} className="btn btn-block">
                            Verify Mobile Phone
                        </button>
                    </div>


                    <div className="verify-btn" style={logoutContainer}>

                        <Countdown
                            zeroPadTime={2}
                            key={new Date(userData?.lastSMSTime)}
                            date={new Date(userData?.lastSMSTime)}
                            renderer={({ hours, minutes, seconds, completed }) => {
                              if (completed) {
                                // Render a completed state
                                // timeUp();
                                return <span style={{...systemColorManager.txt_1}} className="mt-40" onClick={resendEmailVerification}>Resend OTP</span>
                                // 
                              } else {
                                // Render a countdown
                                return <span style={{...systemColorManager.txt_1}} className="mt-40">{minutes}:{seconds}</span>;
                              }
                            }}
                        />

                        {logout()}

                    </div>


                </>
                }





            </AuthHolder>

        </SiteWide>
    );

}

export default UserVerification;