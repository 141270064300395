import React from "react";
import {Link} from "react-router-dom";


export default function WebLink(props){

	const {to, children, ...rest} = props;

	return <Link to={to} {...rest} >{children}</Link>

}

