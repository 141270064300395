import * as actionTypes from "../actions/actionTypes";

const initialState = {
  trades: null,
};

export default (state = initialState, action = {}) => {

  switch (action.type) {
    case actionTypes.TRADE_SAVE:
      return {
        ...state,
        trades: action.data,
      };

    default:
      return state;
  }
};
